<template>
  <div
    v-if="anchor"
    :class="['stats-details', showSidebar ? 'stats-details--sidebar-open' : '']"
  >
    <template>
      <div class="stats-details__content">
        <LiveMap
          :show-anchors="false"
          :interval-update="false"
          :enumerate-tags="enumerateTags"
          :route-selected="routeSelected"
          :stayed-trip="stayedTrip"
          :checkpoint-frequency="checkpointFrequency"
          :show-numbered-only="true"
          @toggle-sidebar="value => (showSidebar = value)"
        >
          <div class="sidebar">
            <StatsLapsCharts
              :routeSelected="routeSelected"
              :redirectedInfo="redirectedInfo"
              :routeId="routeId"
              :anchorId="anchor.data.id"
              :routeList="routeList"
              @done="resetSubmit"
              @stayed-trip="value => (stayedTrip = value)"
              @checkpoint-frequency="value => (checkpointFrequency = value)"
              @route-selected-filter-change="
                value => changeRouteSelected(value)
              "
            />
          </div>
        </LiveMap>
      </div>

      <div class="stats-sidebar" v-loading.lock="routesQuery.loading">
        <el-table
          :data="routes.data"
          border
          style="width: 100%"
          @row-click="onRouteClicked"
          :row-class-name="tableRowClassName"
        >
          <el-table-column prop="id" label="Lap ID" :resizable="false">
          </el-table-column>
          <el-table-column label="Beacons" :resizable="false">
            <template slot-scope="scope">
              {{ scope.row.tags.length }}
            </template>
          </el-table-column>
          <el-table-column label="Start date" :resizable="false">
            <template slot-scope="scope">
              {{ moment.unix(scope.row.startDate).format("DD/MM/YYYY") }}
            </template>
          </el-table-column>
          <el-table-column label="End date" :resizable="false">
            <template slot-scope="scope">
              <template v-if="scope.row.endDate">
                {{ moment.unix(scope.row.endDate).format("DD/MM/YYYY") }}
              </template>
              <template v-else>
                Ongoing
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-button
          v-if="routes.next"
          type="primary"
          tabindex="-1"
          slot="append"
          icon="el-icon-plus"
          :loading="routes.loading"
          @click="loadRoutes(false, true)"
          >Load More</el-button
        >
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment";
import { mapState, mapActions } from "vuex";
import * as actionTypes from "@/store/action-types";
import * as routerTypes from "@/router/router.types";
import routesApi from "@/modules/routes/api/routes.api";
import LiveMap from "@/views/LiveMap.vue";
import StatsLapsCharts from "@/components/stats/StatsLapsCharts";

export default {
  name: "StatsLapsDetails",

  components: {
    LiveMap,
    StatsLapsCharts
  },

  props: {
    duration: {
      default: null
    },
    error: {
      default: null
    },
    startDate: {
      default: null
    },
    routeId: {
      default: null
    }
  },

  computed: {
    ...mapState("anchors", {
      routes: "routes",
      anchor: "anchor"
    }),

    ...mapState("user", {
      resources: "resources"
    }),

    ...mapState("clients", {
      selectedAnchor: "selectedAnchor"
    })
  },

  data() {
    return {
      checkpointFrequency: [],
      closedMenu: null,
      dateSelected: [],
      enumerateTags: [],
      endHour: null,
      moment,
      pickerOptions: {
        disabledDate: null,
        shortcuts: null
      },
      redirectedInfo: null,
      routeSelected: null,
      routeList: [],
      routesQuery: {
        page: 0,
        pageSize: 10,
        loading: false
      },
      showSidebar: false,
      startHour: null,
      stayedTrip: [],
      submit: false
    };
  },

  watch: {
    "$route.params.routeId"(newValue, oldValue) {
      if (newValue === null && !oldValue) {
        this.loadRoutes(true);
      }
    },
    showSidebar: {
      handler(newValue) {
        this.closedMenu = !newValue;
      }
    }
  },

  methods: {
    ...mapActions("anchors", {
      getAnchorRoutes: actionTypes.ANCHOR_GET_ROUTES
    }),

    changeRouteSelected(value) {
      this.onRouteClicked(value);
    },

    resetSubmit() {
      this.submit = false;
      this.redirectedInfo = null;
    },

    async fetchRouteList() {
      try {
        const res = await routesApi.getRouteList();
        this.routeList = res.data;
        this.routeList.sort((a, b) => {
          if (b.anchors.length !== a.anchors.length)
            return b.anchors.length - a.anchors.length;
          else return b.startDate - a.startDate;
        });
      } catch (error) {
        console.log(error);
      }
    },

    async loadRoutes(reset = false, loadMore = false) {
      await this.fetchRouteList();

      if (reset) this.routesQuery.page = 0;

      const anchorId = this.selectedAnchor.anchorId;
      const query = {
        page: this.routesQuery.page++
      };

      query["page_size"] = this.routesQuery.pageSize;

      if (reset) this.routesQuery.loading = true;
      await this.getAnchorRoutes({ anchorId, query });

      let routeToStart = null;
      if (this.routes.data.length && !loadMore) {
        if (this.$route.params.routeId) {
          routeToStart = this.routeList.length
            ? this.routeList.find(
                route => route.id == this.$route.params.routeId
              )
            : null;

          if (!routeToStart && this.routes.next) {
            return this.loadRoutes();
          }
        }

        if (!routeToStart || !this.$route.params.routeId) {
          // routeToStart = this.routeList.reduce(
          //   (previous, current) => {
          //     return current.createdDate > previous.createdDate
          //       ? current
          //       : previous;
          //   },
          //   { createdDate: 0 }
          // );
          routeToStart =
            this.anchor.data.activeRoute ?? this.routeList.length
              ? this.routeList[0]
              : {};
        }
        this.onRouteClicked(routeToStart);
      } else if (!this.routes.data.length && !loadMore) {
        routeToStart = this.routeList.length ? this.routeList[0] : {};
        this.onRouteClicked(routeToStart);
      }

      this.routesQuery.loading = false;
    },

    onRouteClicked(route) {
      if (
        !this.$route.params.routeId ||
        (this.$route.params.routeId &&
          (route?.id ?? null) !== Number(this.$route.params.routeId))
      ) {
        this.$router
          .push({
            name: routerTypes.ROUTE_STATS_LAPS_DETAILS,
            params: {
              anchorId: this.$route.params.anchorId,
              routeId: route?.id
            }
          })
          // eslint-disable-next-line
          .catch(_ => {
            // Ignore
          });
      }
      this.routeSelected = route;
      this.enumerateTags = this.routeSelected?.tags ?? [];
      this.getTagIdsFromUserTags();
    },

    tableRowClassName({ row }) {
      if (row.id === Number(this.$route.params.routeId)) {
        return "row-active";
      }
    },

    getTagIdsFromUserTags() {
      this.enumerateTags?.forEach(tag => {
        const userTag = this.resources.data.tags.find(
          _tag => tag.tagPositionId === _tag.id
        );

        if (userTag) {
          tag.tagId = userTag.tagId;
          tag.lastPosition = tag.tagPosition;
          //tag = userTag;
        }
      });
    }
  },

  created() {
    if (this.duration !== null) {
      this.redirectedInfo = {
        duration: this.duration,
        startDate: this.startDate,
        error: this.error
      };
    }
    this.loadRoutes(true);
  }
};
</script>

<style lang="scss">
.stats-details {
  .sidebar {
    display: block !important;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    padding-top: 60px;
    padding-bottom: 20px;
    background: $--border-color-extra-light;
    width: 55vw;
    max-height: 100vh;
    overflow-y: auto;

    @media (max-width: 1740px) {
      width: 911px;
      z-index: 30;
      border-left: 1px solid $--border-color-base;
    }

    @media (max-width: 1300px) {
      width: 100%;
    }
  }
  &__empty-state {
    max-width: 600px;
    margin: 48px auto;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    display: flex;
  }

  &--sidebar-open {
    > .stats-sidebar {
      display: block !important;
      position: fixed;
      top: 104px;
      right: 0;
      bottom: 0;
      padding-top: 60px;
      padding-bottom: 20px;
      background: $--border-color-extra-light;
      width: 45vh;
      max-height: 100vh;
      overflow-y: auto;
      overflow-x: hidden;
      border-left: 1px solid #dcdfe6;
      z-index: -1;

      &::after {
        content: " ";
        position: absolute;
        top: 0;
        width: 480px;
        height: 60px;
        background: $--border-color-extra-light;
      }

      .el-date-editor {
        margin-top: 12px;
      }

      .el-table {
        margin-bottom: 26px;
      }

      .el-button {
        margin-bottom: 20px;
      }
    }

    .stats-details {
      &__content {
        grid-template-columns: 35% 40% !important;
      }
    }

    .stats-charts {
      width: 100% !important;
    }

    .dashboard__map-wrapper {
      border-top: none;
      width: 100%;
      padding-top: 0;
      position: fixed;
      max-height: calc(100vh - 104px);
    }
  }

  @media (max-width: 470px) {
    .dashboard .leaflet-control-zoom.leaflet-bar.leaflet-control {
      top: 10px;
    }

    .dashboard__fit-to-bounds {
      top: 88px;
    }

    .dashboard__tag-slider {
      top: 138px;
    }

    .dashboard__slider {
      top: 47px;
    }
  }
  @media (max-width: $md) and (min-width: 471px) {
    .dashboard__fit-to-bounds {
      top: 78px;
    }
  }

  .dashboard__map-wrapper,
  .dashboard__slot {
    height: 100%;
    padding-top: 0;
    border-bottom: none;
  }

  .dashboard__map.leaflet-container {
    height: 100%;
    border: none;
  }

  &__content {
    .dashboard {
      padding-top: 0;
      height: calc(100vh - 60px);
    }

    .dashboard__map-wrapper {
      position: relative;
      width: 100%;

      @media (max-width: $md) {
        border-top: none;
      }
    }

    .dashboard__slot {
      position: relative;
      padding-top: 24px;
      width: 55vw;
      height: 100%;
      float: left;
      overflow: auto;

      @media (max-width: $sm) {
        width: 100%;
      }
    }

    .dashboard--has-slot {
      .dashboard__map-wrapper {
        height: calc(100vh - 60px);
        width: calc(100% - 55vw);
        float: left;
        border: none;

        // @media (max-width: 1900px) {
        //   width: calc(100% - 911px);
        // }
      }
    }

    .stats-charts {
      margin-top: 20px;
      margin-left: 2%;
      width: 90%;

      @media (max-width: $md) {
        margin-top: 16px;
        width: auto;
        margin-right: 2%;
      }
    }
  }

  .stats-sidebar {
    display: none;
  }

  .toggle-dashboard-slot {
    position: fixed;
    top: 75px;
    right: 0px;

    @media (max-width: $sm) {
      top: 75px;
    }
  }

  .el-table__row {
    cursor: pointer;

    &.row-active > td {
      background-color: #f5f7fa;
    }
  }
}
</style>
