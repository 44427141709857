<template>
  <div class="stats-charts">
    <div class="container">
      <div class="date-hour-pickers">
        <el-date-picker
          ref="picker"
          v-model="dateSelected"
          type="daterange"
          range-separator="To"
          start-placeholder="Start date"
          end-placeholder="End date"
          format="dd/MM/yyyy"
          value-format="yyyy-MM-dd"
          :picker-options="pickerOptions"
        >
        </el-date-picker>

        <div class="tag-picker">
          <el-select
            v-model="search"
            placeholder="Select beacon"
            filterable
            size="small"
            class="search-input"
          >
            <el-option
              v-for="tag in resources.data.tags"
              :key="`tag.label${tag.tagId}`"
              :label="
                `(${tag.tagId
                  .toString(16)
                  .toUpperCase()
                  .match(/.{1,2}/g)
                  .join('')}) ${tag.label}`
              "
              :value="tag.tagId"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="download-csv">
        <el-button type="success" size="mini" @click="downloadCSV(false)"
          >Download Position History CSV</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from "vuex";
import tagsApi from "@/modules/tags/api/tags.api";
import moment from "moment";
export default {
  data() {
    return {
      moment,
      search: "",
      pickerOptions: {
        disabledDate: null,
        shortcuts: null
      },
      dateSelected: [
        moment()
          .clone()
          .subtract(14, "days")
          .startOf("day")
          .format("yyyy-MM-DD"),
        moment().format("yyyy-MM-DD")
      ]
    };
  },
  props: {
    showOperations: {
      type: Boolean,
      default: true
    },
    showActiveRoute: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    ...mapState("user", {
      resources: "resources"
    }),
    ...mapGetters("auth", ["isAdmin"])
  },

  methods: {
    async downloadCSV(details) {
      let res = null;

      const startDate = moment.utc(this.dateSelected[0]).unix();
      let endDate = moment.utc(this.dateSelected[1]).unix();
      const SECONDS_ID_DAY = 86400;
      endDate = endDate + SECONDS_ID_DAY - 1;

      try {
        res = await tagsApi.getCSVinformation(this.search, startDate, endDate);
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      }
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(res.data);
      anchor.target = "_blank";
      anchor.download = "beacon-history.csv";
      anchor.click();
    }
  }
};
</script>

<style lang="scss">
.stats-charts {
  .tag-picker {
    margin-top: 1%;
  }

  .date-hour-pickers {
    margin-bottom: 1%;
  }
}
</style>
