<template>
  <div class="login">
    <div id="particles-js" style="width: 100%; height: 100%;"></div>
    <el-form
      label-position="top"
      :model="loginForm"
      status-icon
      :rules="rules"
      ref="loginForm"
      label-width="120px"
      class="login__form"
      hide-required-asterisk
      :disabled="user.loading"
    >
      <img class="login__logo" src="@/assets/logo.svg" alt="Azitek" />

      <h3>Login into your account</h3>

      <el-form-item label="Email" prop="email">
        <el-input
          type="text"
          v-model="loginForm.email"
          autocomplete="off"
          @keyup.enter.native="submitForm('loginForm')"
          @input="onInputChange()"
        ></el-input>
      </el-form-item>

      <el-form-item label="Password" prop="password">
        <el-input
          :type="revealPassword ? 'text' : 'password'"
          v-model="loginForm.password"
          autocomplete="off"
          @keyup.enter.native="submitForm('loginForm')"
          @input="onInputChange()"
        >
          <el-button
            tabindex="-1"
            @click="revealPassword = !revealPassword"
            slot="append"
            icon="el-icon-view"
          ></el-button>
        </el-input>
        <div
          v-if="loginForm.error.length"
          class="el-form-item__error el-form-item__error--server"
        >
          {{ loginForm.error }}
        </div>
      </el-form-item>
      <el-form-item>
        <div @click="forgotPassword()" class="forgot-password">
          Forgot Password?
        </div>
      </el-form-item>
      <el-form-item>
        <el-button
          type="primary"
          @click="submitForm('loginForm')"
          :loading="user.loading"
        >
          Login
        </el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script lang="js">
import 'particles.js/particles';
import { mapActions, mapState, mapGetters } from "vuex";
import authApi from "@/modules/auth/api/auth.api";
import * as actionTypes from "@/store/action-types";
import {
    ROUTE_LIVE_GLOBAL_MAP,
    ROUTE_USER_SETTINGS,
    ROUTE_CARGO_CHECK,
    ROUTE_INVENTORY_MANAGER,
    ROUTE_ASSET_MANAGER,
    ROUTE_DASHBOARD
} from "@/router/router.types";

export default {
  name: "SessionLogin",

  data() {
    return {
      revealPassword: false,
      loginForm: {
        email: "",
        password: "",
        error: ''
      },
      rules: {
        email: [
          { required: true, message: 'Please enter your email address', trigger: 'blur' },
          { type: 'email', message: 'Please enter a valid email address', trigger: 'blur' }
        ],
        password: [
          { required: true, message: 'Please enter your password', trigger: 'blur' }
        ]
      },
      passwordRules: {
        email: [
          { required: true, message: 'Please enter your email address', trigger: 'blur' },
          { type: 'email', message: 'Please enter a valid email address', trigger: 'blur' }
        ],
      }
    };
  },

  computed: {
    ...mapState("auth", {
      user: "user"
    }),
    ...mapGetters("auth", ["isChangePasswordUser"]),
    ...mapGetters("clients", ["isClientDefault"])
  },

  watch: {
    "user.data.email"(newValue) {
      if (newValue && newValue.length > 0) {
        if (!this.isChangePasswordUser && this.user.data.clientType == 0)
          this.$router.replace({ name: ROUTE_LIVE_GLOBAL_MAP });
        else if (this.user.data.clientType == 1)
          this.$router.replace({ name: ROUTE_CARGO_CHECK });
        else if (this.user.data.clientType == 2)
          this.$router.replace({ name: ROUTE_DASHBOARD });
        else if (this.user.data.clientType == 4)
          this.$router.replace({ name: ROUTE_ASSET_MANAGER })
        else if (this.user.data.clientType == 3)
          this.$router.replace({ name: ROUTE_INVENTORY_MANAGER });
        else
          this.$router.replace({ name: ROUTE_USER_SETTINGS });
      }
    },
    "user.error": {
      deep: true,
      handler: function(newValue) {
        if (newValue != null) {
          if (newValue.status === 401) {
            this.loginForm.error = 'Wrong username/password'
          } else {
            this.loginForm.error = 'Something went wrong. Try again later.'
          }
        }
      }
    }
  },

  methods: {
     ...mapActions("auth", {
      authToken: actionTypes.AUTH_LOGIN
    }),

    onInputChange () {
      if (this.loginForm.error) {
        this.loginForm.error = ''
      }
    },

    async forgotPassword() {
      if (!this.loginForm.email) {
        this.$notify.error({
          title: "Empty Email",
          message: "Please enter your e-mail address."
        });
        return;
      }
      try {
        const res = await authApi.forgotPassword(this.loginForm.email)
        console.log(res)
        this.$notify({
          title: "Email sent!",
          message: "If you have typed in your correct email address, you should receive one soon to continue the process.",
          type: "success"
        })
      } catch(err) {
        this.$notify.error({
          title: "Error",
          message: err.response.data.detail + ' Don\'t forget to also check your spam folder.'
        });
      }
    },

    submitForm(formName) {
      this.$refs[formName].validate(valid => {
        if (valid) {
          this.authToken({
            email: this.loginForm.email.toLowerCase(),
            password: this.loginForm.password
          });
        }
      });
    }
  },

  mounted () {
    window.particlesJS('particles-js', {
      particles: {
        number: {
          value: 200,
          density: {
            enable: true,
            'value_area': 1400
          }
        },
        size: {
          value: 0.5
        },
        move: {
          speed: 0.75
        }
      },
      interactivity: {
        events: {
          onclick: {
            enable: true,
            mode: 'push'
          }
        }
      }
    });
  }
};
</script>

<style lang="scss">
.login {
  width: inherit;
  height: inherit;
  position: relative;
  min-width: inherit;
  min-height: inherit;
  display: flex;
  align-items: center;
  justify-content: center;

  .forgot-password {
    float: left;
    font-size: 12px;
    margin-top: -22px;
    margin-bottom: -22px;
    font-weight: bold;
    text-decoration: underline;

    &:hover {
      cursor: pointer;
    }
  }

  #particles-js {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: radial-gradient(
      circle,
      rgb(101, 141, 165) 0%,
      rgb(27, 60, 80) 100%
    );
    z-index: -1;
  }

  &__logo {
    margin-bottom: 40px;
    max-width: 130px;
  }

  h3 {
    position: relative;
    margin-bottom: 30px;

    &::before {
      content: "";
      position: absolute;
      top: -20px;
      left: 0;
      width: 100%;
      height: 1px;
      background-image: radial-gradient(circle at 50% 0, #e5eaec, #fff);
    }
  }

  &__form {
    flex: 0 0 250px;
    background: white;
    padding: 24px;
    border-radius: 8px;

    @media (min-width: $xs) {
      flex: 0 0 320px;
    }

    .el-form-item label {
      display: block;
      padding: 0;
      padding-left: 2px;
      line-height: 20px;

      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
    }

    .el-button {
      width: 100%;
      margin-top: 10px;
    }
  }

  .el-form .el-form-item .el-input-group__append i {
    position: relative;
    left: -5px;
  }
}
</style>
