export const ROUTE_SESSION_LOGIN = "login";
export const ROUTE_DASHBOARD = "dashboard";
export const ROUTE_STATS_GLOBALS = "stats-globals";
export const ROUTE_STATS_LAPS = "stats-laps";
export const ROUTE_STATS_LAPS_DETAILS = "stats-laps-details";
export const ROUTE_PREDICTIONS_GLOBAL = "predictions-global-view";
export const ROUTE_PREDICTIONS_FULLSCREEN = "predictions-fullscreen-mode";
export const ROUTE_USER_SETTINGS = "user-settings";
export const ROUTE_USER_MAIN_TAB = "routes";
export const ROUTE_USER_MAIN_TAB_STATIONARY = "account";
export const ROUTE_USER_ACCOUNT = "account";
export const ROUTE_USER_CHANGE_PASSWORD = "user-change-password";
export const ROUTE_FORGOT_PASSWORD = "forgot-password";
export const ROUTE_CARGO_CHECK = "cargo-check";
export const ROUTE_INVENTORY_MANAGER = "inventory-manager";
export const ROUTE_ASSET_MANAGER = "asset-manager";
export const ROUTE_LIVE_GLOBAL_MAP = "live-global-map";
