<template>
  <div class="global-fullscreen-wrapper">
    <div class="container">
      <h4 v-if="anchors.isEmpty">
        You must have an anchor to see data on this page.
      </h4>
    </div>
    <template v-if="!isLoading">
      <div class="global-fullscreen-wrapper__details__clock">
        <i class="el-icon-timer" />
        {{ moment.tz(this.clientData.timezone).format("HH:mm") }}
      </div>
      <div class="global-display">
        <div
          class="container global-fullscreen-wrapper__details"
          v-for="anchor in anchors.data"
          v-bind:key="anchor.id"
        >
          <template v-if="anchor.activeRoute">
            <div
              v-if="anchor.activeRoute"
              class="global-fullscreen-wrapper__anchor"
            >
              <b>Label: </b> {{ anchor.label }} <b>ID: </b>
              {{
                anchor.anchorId
                  .toString(16)
                  .padStart(12, "0")
                  .toUpperCase()
                  .match(/.{1,2}/g)
                  .join(":")
              }}
              <WifiIcon
                :signal="anchor.rssi"
                :noConnection="
                  anchor.lastHeartbeat
                    ? isAnchorConnected(anchor.lastHeartbeat.createdDate)
                    : false
                "
              />
              <span
                v-if="
                  anchor.lastHeartbeat
                    ? isAnchorConnected(anchor.lastHeartbeat.createdDate)
                    : false
                "
                class="anchor__offline"
              >
                Offline
              </span>
              <el-tooltip
                placement="right"
                content="Vehicle Prediction"
                :hide-after="2000"
                class="eye-button"
              >
                <el-button
                  @click="redirectToPredictions(anchor.anchorId)"
                  size="mini"
                >
                  <img src="@/assets/dark-eye.svg" alt="eye" />
                </el-button>
              </el-tooltip>
            </div>
            <div class="global-fullscreen-wrapper__options">
              <template v-if="anchor.activeRoute">
                <div class="global-fullscreen-wrapper__options--left">
                  <p>Select a tag to follow:</p>
                  <el-select
                    v-model="anchor.tagOnTopOrd"
                    :change="setData(anchor)"
                    placeholder="based on tag"
                  >
                    <el-option
                      v-for="tag in anchor.selectData"
                      :key="`(${tag.ord}) ${tag.label || tag.tagId}`"
                      :label="`(${tag.ord}) ${tag.label || tag.tagId}`"
                      :value="[tag.ord || '', anchor.anchor_id]"
                    >
                    </el-option>
                  </el-select>
                </div>
                <div class="global-fullscreen-wrapper__options--right">
                  <p>Number of elements in table:</p>
                  <el-input-number
                    v-model="anchor.numberOfRows"
                    @change="setTableData(anchor)"
                    :min="1"
                    :max="Math.min(anchor.activeRoute.tags.length, 15)"
                  ></el-input-number>
                  <!--<el-button type="primary" @click="toggle"
                    ><i class="el-icon-full-screen" /> Go Fullscreen</el-button
                  >-->
                </div>
              </template>
              <h4 v-else>
                This gateway doesn't have an active route. Go to the
                <router-link
                  :to="{
                    name: 'user',
                    params: { tab: 'routes', anchorId: $route.params.anchorId }
                  }"
                  >routes tab</router-link
                >
                to configure one.
              </h4>
            </div>
            <fullscreen
              v-if="anchor.activeRoute"
              class="fullscreen__on"
              ref="fullscreen"
              @change="fullscreenChange"
            >
              <div
                v-if="predictions - fullscreen - mode"
                class="fullscreen__close"
                @click="toggle()"
              >
                <i class="el-icon-close" />
              </div>
              <template v-if="anchor.tableData">
                <el-table
                  ref="predictions"
                  highlight-current-row
                  :class="
                    `table__rows--${Math.min(
                      Math.min(10, anchor.tableData.length),
                      Math.min(anchor.numberOfRows, 10)
                    )}`
                  "
                  :row-class-name="tableRowClassName"
                  :data="anchor.tableData"
                  border
                  style="width: 100%"
                >
                  <el-table-column
                    :resizable="false"
                    label="Label"
                    :min-width="67"
                  >
                    <template slot-scope="scope">
                      {{ getTagInfo(anchor.selectData, scope.row.ord).label }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :resizable="false"
                    label="Time to arrival"
                    :min-width="33"
                  >
                    <template slot-scope="scope">
                      {{
                        moment
                          .tz(
                            moment.unix(scope.row.prediction),
                            clientData.timezone
                          )
                          .format("HH:mm")
                      }}
                    </template>
                  </el-table-column>
                </el-table>
              </template>
              <template v-if="predictions - fullscreen - mode">
                <template v-if="anchors.data[1].tableData">
                  <el-table
                    ref="predictions"
                    highlight-current-row
                    :class="
                      `table__rows--${Math.min(
                        Math.min(5, anchors.data[1].tableData.length),
                        Math.min(anchors.data[1].numberOfRows, 5)
                      )}`
                    "
                    :row-class-name="tableRowClassName"
                    :data="anchors.data[1].tableData"
                    border
                    style="width: 100%"
                  >
                    <el-table-column :resizable="false" label="Label">
                      <template slot-scope="scope">
                        {{
                          getTagInfo(anchors.data[1].selectData, scope.row.ord)
                            .label
                        }}
                      </template>
                    </el-table-column>
                    <el-table-column :resizable="false" label="Time to arrival">
                      <template slot-scope="scope">
                        {{ moment.unix(scope.row.prediction).format("HH:mm") }}
                      </template>
                    </el-table-column>
                  </el-table>
                </template>
              </template>
            </fullscreen>
          </template>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState, mapActions } from "vuex";
import * as actionTypes from "@/store/action-types";
import * as routerTypes from "@/router/router.types";
import AnchorsApi from "@/modules/anchors/api/anchors.api";
import WifiIcon from "@/components/icons/WifiIcon.vue";

export default {
  components: {
    WifiIcon
  },

  computed: {
    ...mapState("clients", {
      anchors: "anchors",
      selectedAnchor: "selectedAnchor",
      clientData: "data"
    }),
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      resources: "resources"
    }),
    computedColor() {
      if (this.anchor.activeRouteOnTime === null) return "#1F4258";
      else {
        if (this.anchor.activeRouteOnTime[0] === 0) return "#1F4258";
        else if (this.anchor.activeRouteOnTime[0] === -1) return "green";
        else return "red";
      }
    },
    computedWeight() {
      if (this.anchor.activeRouteOnTime === null) return "normal";
      else {
        return this.anchor.activeRouteOnTime[0] === 0 ? "normal" : "bold";
      }
    }
  },
  data() {
    return {
      moment,
      currentTime: "##:##",
      currentTimeInterval: null,
      fullscreen: false,
      anchor: null,
      tableData: [],
      selectData: [],
      prediction: [],
      numberOfRows: 5,
      basedOnAnchorPosition: true,
      tagOnTopOrd: "",
      interval: null,
      highligtedRow: false,
      emptyData: false,
      isLoading: false
    };
  },

  watch: {
    /*tagOnTopOrd() {
      this.basedOnAnchorPosition = false;
      this.setData();
    }*/
  },
  methods: {
    ...mapActions("clients", {
      getAnchors: actionTypes.CLIENTS_GET_ANCHORS,
      updateGlobalView: actionTypes.CLIENTS_UPDATE_GLOBAL_VIEW,
      updateSelectedAnchor: actionTypes.CLIENTS_UPDATE_SELECTED_ANCHOR
    }),
    ...mapActions("user", {
      getResources: actionTypes.USER_GET_RESOURCES
    }),
    toggle() {
      this.$refs["fullscreen"][0].toggle();
    },

    fullscreenChange(fullscreen) {
      this.fullscreen = fullscreen;
    },

    setCurrent(row) {
      if (this.$refs.predictions) {
        this.$refs.predictions.setCurrentRow(row);
      }
    },

    getRouteTotalHours() {
      const currentTime = moment().utc();
      const routeStart = moment.unix(this.anchor.activeRouteStart);
      if (Math.floor(currentTime.diff(routeStart, "seconds") / 3600) >= 1)
        return (
          Math.floor(
            currentTime.diff(routeStart, "seconds") / 3600
          ).toString() + "h:"
        );
      else return null;
    },

    getRouteTotalMinutes() {
      return Math.floor(
        (moment()
          .utc()
          .diff(moment.unix(this.anchor.activeRouteStart), "seconds") %
          3600) /
          60
      );
    },

    getRouteTotalSeconds() {
      return Math.floor(
        (moment()
          .utc()
          .diff(moment.unix(this.anchor.activeRouteStart), "seconds") %
          3600) %
          60
      );
    },

    getRouteDelay() {
      let hours;
      let minutes;
      if (this.anchor.activeRouteOnTime[1] >= 0) {
        hours = Math.floor(this.anchor.activeRouteOnTime[1] / 3600);
        minutes = Math.floor((this.anchor.activeRouteOnTime[1] % 3600) / 60);
      } else {
        hours = Math.ceil(this.anchor.activeRouteOnTime[1] / 3600);
        minutes = Math.ceil((this.anchor.activeRouteOnTime[1] % 3600) / 60);
      }

      if (Math.abs(hours) >= 1)
        return (
          (minutes > 0 ? "+" + hours.toString() : hours.toString()) +
          "h:" +
          minutes.toString() +
          "m"
        );
      else
        return (
          (minutes > 0 ? "+" + minutes.toString() : minutes.toString()) + "m"
        );
    },

    getTimeDiff(unixDate, unixPrediction) {
      const date = moment(moment.unix(unixDate), "DD/MM/YYYY HH:mm:ss");
      const now = moment(moment.unix(unixPrediction), "DD/MM/YYYY HH:mm:ss");
      const diff = moment.duration(date.diff(now));
      const days = parseInt(diff.asDays());
      const hours = parseInt(diff.asHours());
      let minutes = parseInt(diff.asMinutes());
      minutes = minutes - (days * 24 * 60 + hours * 60);

      const isNegative = hours < 0 || minutes < 0;
      let finalDate = "";

      if (hours != 0) {
        finalDate += `${Math.abs(hours)}h `;
      }

      finalDate += `${Math.abs(minutes)} min`;
      finalDate = isNegative ? `-${finalDate}` : finalDate;

      return {
        status: isNegative ? "negative" : "positive",
        diff: finalDate
      };
    },

    async getAllPredictions() {
      const _self = this; // eslint-disable-line
      this.anchors.data.forEach(anchor => {
        _self.getAnchorPrediction(anchor);
      });
    },

    calculatePredictionTable(anchor) {
      if (
        anchor.currentRoutePositionLog == null ||
        anchor.currentRouteInstance == null ||
        anchor.predictionAvgs == undefined
      )
        return;
      const positionInfo = anchor.currentRoutePositionLog;
      const lastPosition = anchor.lastPositionMetadata;

      const orderedArray = anchor.predictionAvgs.data
        .filter(elem => elem.ord > positionInfo.ord)
        .concat(
          anchor.predictionAvgs.data.filter(
            elem => elem.ord <= positionInfo.ord
          )
        );

      const newPredictions = [];

      if (lastPosition.state == "ENTERED") {
        let nextTime =
          lastPosition.enterTs +
          orderedArray[orderedArray.length - 1].avgStay +
          orderedArray[orderedArray.length - 1].avgTrip;
        if (
          nextTime <
          Math.ceil(
            moment()
              .utc()
              .valueOf() / 1000
          )
        )
          nextTime =
            Math.ceil(
              moment()
                .utc()
                .valueOf() / 1000
            ) + orderedArray[orderedArray.length - 1].avgTrip;

        newPredictions.push({
          tagPositionId: orderedArray[0].tagPositionId,
          ord: orderedArray[0].ord,
          prediction: nextTime,
          state: "WAITING"
        });
      } else if (lastPosition.state == "LEFT") {
        let nextTime =
          lastPosition.lastSeenTs +
          orderedArray[orderedArray.length - 1].avgTrip;
        if (
          nextTime <
          Math.ceil(
            moment()
              .utc()
              .valueOf() / 1000
          )
        )
          nextTime = Math.ceil(
            moment()
              .utc()
              .valueOf() / 1000
          );

        newPredictions.push({
          tagPositionId: orderedArray[0].tagPositionId,
          ord: orderedArray[0].ord,
          prediction: nextTime,
          state: "ON_THE_WAY"
        });
      } else {
        //this.updateAnchor();
        this.emptyData = true;
        return;
      }

      for (let i = 1; i < orderedArray.length; i++) {
        newPredictions.push({
          tagPositionId: orderedArray[i].tagPositionId,
          ord: orderedArray[i].ord,
          prediction:
            newPredictions[i - 1].prediction +
            orderedArray[i].avgStay +
            orderedArray[i].avgTrip,
          state: "WAITING"
        });
      }

      if (lastPosition.state == "ENTERED") {
        newPredictions[newPredictions.length - 1].prediction =
          lastPosition.enterTs;
        newPredictions[newPredictions.length - 1].state = "CURRENTLY_HERE";
      } else if (lastPosition.state == "LEFT") {
        newPredictions[newPredictions.length - 1].state = "LEFT";
      }

      newPredictions.unshift(newPredictions.pop());

      // Keep updating anchor info to get the connection status
      // this.updateAnchor(anchor);

      if (newPredictions.length > 0) {
        anchor.prediction = newPredictions;
        this.setSelectData(anchor);
        this.setTableData(anchor);
      } else {
        anchor.tableData = [];
        anchor.emptyData = true;
      }

      this.$forceUpdate();
    },

    async getAnchorPrediction(anchor) {
      if (anchor.activeRoute) {
        try {
          anchor.predictionAvgs = await AnchorsApi.getAnchorPredictionAvgs(
            anchor.anchorId
          );

          this.calculatePredictionTable(anchor);
        } catch (err) {
          if (err.response.status !== 422 && err.response.status !== 404) {
            this.$notify.error({
              title: "Error",
              message: "Something went wrong. Please contact support."
            });
          }
        }
      }
    },

    setData(anchor) {
      if (anchor.prediction) {
        this.setSelectData(anchor);
        this.setTableData(anchor);
      }
    },

    setSelectData(anchor) {
      anchor.selectData = [];
      this.resources.data.tags.forEach(_tag => {
        let prediction = anchor.prediction.filter(
          prediction => prediction.tagPositionId === _tag.lastPosition?.id
        );

        if (prediction.length) {
          prediction = prediction.map(_entry => {
            _entry.tagId = _tag.tagId;
            _entry.label = _tag.label;
            return _entry;
          });
          anchor.selectData.push(...prediction);
        }
      });

      // ord data on select by "ord" in active route
      anchor.selectData = anchor.selectData.sort((a, b) =>
        a.ord > b.ord ? 1 : -1
      );
    },

    setTableData(anchor) {
      //if (anchor.emptyData) return false;

      anchor.tableData = [];
      let topTagIndex = -1;

      if (!anchor.tagOnTopOrd) {
        topTagIndex = anchor.prediction.findIndex(
          prediction => prediction.state === "CURRENTLY_HERE"
        );

        if (topTagIndex < 0) {
          topTagIndex = anchor.prediction.findIndex(
            prediction => prediction.state === "LEFT"
          );
        }
      } else {
        topTagIndex = anchor.prediction.findIndex(
          prediction => prediction.ord === anchor.tagOnTopOrd[0]
        );
      }

      let l = 0,
        i = Math.max(0, topTagIndex);

      //if (anchor.selectData.filter(element => element.ord === 0).length !== 0)
      if (anchor.numberOfRows)
        anchor.numberOfRows = Math.min(
          Math.min(anchor.selectData.length, 15),
          anchor.numberOfRows
        );
      else anchor.numberOfRows = Math.min(anchor.selectData.length, 5);

      if (!this.fullscreen)
        while (l < anchor.numberOfRows) {
          if (i === anchor.prediction.length) i = 0;
          anchor.tableData.push(anchor.prediction[i]);
          l++;
          i++;
        }
      else
        while (l < 10) {
          if (i === anchor.prediction.length) i = 0;
          anchor.tableData.push(anchor.prediction[i]);
          l++;
          i++;
        }
    },

    getTagInfo(selectData, ord) {
      const _tag = selectData.find(tag => tag.ord === ord);
      return { label: _tag.label, tagId: _tag.tagId };
    },

    isAnchorConnected(createdDate) {
      const oneMinuteAgo = moment()
        .utc()
        .subtract(60, "seconds");

      return !moment.unix(createdDate).isAfter(oneMinuteAgo);
    },

    tableRowClassName(prediction) {
      if (prediction.row.state === "CURRENTLY_HERE") {
        return "currently-here";
      }

      if (prediction.row.state === "ON_THE_WAY") {
        return "on-the-way";
      }

      return "";
    },

    async updateAnchor(anchor) {
      try {
        const { data } = await AnchorsApi.getAnchorSimplified(anchor.anchorId);
        this.anchors.data.forEach(element => {
          if (element.anchorId === anchor.anchorId) {
            element.lastHeartbeat = data.lastHeartbeat;
            element.currentRoutePositionLog = data.currentRoutePositionLog;
            element.lastPositionMetadata = data.lastPositionMetadata;
          }
        });

        if (anchor.activeRoute)
          if (anchor.currentRoute != anchor.activeRoute.id) {
            this.getAnchorPrediction(anchor);
            anchor.currentRoute = anchor.activeRoute.id;
          }
      } catch (_) {
        // DO nothing
      }
    },
    calculateAllPredictionTables() {
      const _self = this; // eslint-disable-line
      this.anchors.data.forEach(anchor => {
        _self.calculatePredictionTable(anchor);
        _self.updateAnchor(anchor);
      });
    },
    redirectToPredictions(anchorId) {
      this.updateSelectedAnchor(anchorId);
      this.$router.push({
        name: routerTypes.ROUTE_PREDICTIONS_FULLSCREEN
      });
    }
  },

  async created() {
    this.isLoading = true;
    const _self = this; // eslint-disable-line

    this.getAllPredictions();

    await this.anchors.data.forEach(anchor => {
      _self.getAnchorPrediction(anchor);
      if (anchor.activeRoute) anchor.currentRoute = anchor.activeRoute.id;
      else anchor.currentRoute = 0;
      //this.interval = setInterval(this.getAnchorPrediction(anchor), 2000);
    });

    // this.anchor = this.selectedAnchor;

    //this.interval = setInterval(this.getAllPredictions, 2000);

    this.interval = setInterval(this.calculateAllPredictionTables, 2000);

    this.currentTime = moment().format("HH:mm");
    this.currentTimeInterval = setInterval(
      function(_self) {
        _self.currentTime = moment().format("HH:mm");
      },
      60000,
      this
    );
    this.isLoading = false;
    this.updateGlobalView(true);
  },

  beforeDestroy() {
    if (this.interval) {
      clearInterval(this.interval);
    }

    if (this.currentTimeInterval) {
      clearInterval(this.currentTimeInterval);
    }
  }
};
</script>

<style lang="scss">
.global-fullscreen-wrapper {
  padding: 24px 0;

  &__details {
    flex: 50%;
  }

  &__anchors {
    .el-table__body td {
      cursor: pointer;
    }
  }

  &__anchor {
    margin-top: -3%;
    .wifi__no-connection {
      top: 0;
    }

    svg {
      position: relative;
      top: 20px;
      left: 7px;
    }
  }

  .anchor__offline {
    margin-left: 12px;
    color: $--color-danger;
    font-weight: bold;
  }

  &__details {
    &__clock {
      display: inline-block;
      color: white;
      background-color: #2f2d2d91;
      border-radius: 4px;
      padding: 8px;
    }

    .el-table {
      max-width: 98%;
      margin: 0 auto;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }

    .el-table__header-wrapper .el-table__header thead tr th {
      background-color: $--color-primary;

      > .cell {
        text-align: center;
        color: white;
        font-size: 24px;
      }
    }

    .el-table__body-wrapper .el-table__body tbody tr td {
      background-color: #f7fcff;

      > .cell {
        text-align: center;
        color: $--color-primary;
        font-size: 24px;

        .entry--negative {
          color: $--color-danger;
        }

        .entry--positive {
          color: $--color-success;
        }
      }
    }

    .el-table__row {
      height: 80px;
    }

    .cell {
      height: auto;
      min-height: 27px;
    }

    .el-table__row td {
      background-color: transparent !important;
    }

    .el-table__row.currently-here {
      background: rgba(50, 205, 50, 0.7);

      .cell {
        color: #2c3e50;
        font-weight: bold;
      }
    }

    .el-table__row.on-the-way {
      background: rgba(255, 184, 51, 0.7);
      animation: highlightOnTheWay 1s infinite;

      .cell {
        color: #2c3e50;
        font-weight: bold;
      }
    }

    .el-button {
      margin-bottom: 20px;
    }

    .fullscreen.fullscreen__on {
      position: relative;
      background: white !important;

      .global-fullscreen-wrapper__anchor {
        position: relative;
        top: -18px;
        background: white;
      }

      .global-fullscreen-wrapper__details__clock {
        position: absolute;
        bottom: 0;
        right: 15px;
        z-index: 1000;
      }

      .el-table {
        height: calc(100vh - 65px);
        max-width: 100%;
      }

      .el-table.table__rows--5
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(16.66vh - 11px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--5
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(16.66vh - 11px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }

      .el-table.table__rows--6
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(14.28vh - 2px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--6
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(14.28vh - 10px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }

      .el-table.table__rows--7
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(12.5vh - 9px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--7
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(12.5vh - 9px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }

      .el-table.table__rows--8
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(11.11vh - 8px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--8
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(11.11vh - 8px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }

      .el-table.table__rows--9
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(10vh - 7px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--9
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(10vh - 7px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }

      .el-table.table__rows--10
        .el-table__header-wrapper
        .el-table__header
        thead
        tr
        th {
        height: calc(9.09vh - 6px) !important;
        font-size: 3vmin;
      }

      .el-table.table__rows--10
        .el-table__body-wrapper
        .el-table__body
        tbody
        tr
        td {
        height: calc(9.09vh - 6px) !important;
        font-size: 3vmin;

        > .cell {
          i {
            font-size: 6vmin;
          }
        }
      }
    }

    .fullscreen__close {
      position: absolute;
      top: 10px;
      right: 40px;
      z-index: 1000;
      border: 1px solid #dcdfe6;
      padding: 10px 12px;
      font-size: 20px;
      background: rgba(255, 255, 255, 0.8);
      border-radius: 50%;
      cursor: pointer;
    }

    .eye-button {
      margin-left: 10px;
    }
  }

  .global-display {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-left: 5%;
    margin-right: 5%;
    margin-bottom: 10%;
    color: black;
  }

  &__options {
    max-width: 606px;
    display: block;
    margin: 24px auto;
    padding: 24px;
    background: #ffffff;
    border: 1px solid #dcdfe6;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    display: flex;

    &--left {
      flex: 0 0 50%;

      p {
        text-align: left;
        font-size: 13px;
      }

      .el-checkbox {
        width: 100%;
        margin: 14px 0;
        text-align: left;
      }

      .el-select {
        margin-top: 5%;
        width: 100%;
      }
    }

    &--right {
      p {
        text-align: left;
        font-size: 13px;
        margin-left: 56px;
      }

      .el-input-number {
        width: 100%;
        margin: 14px;
        max-width: 190px;
        margin-left: 24%;
      }
    }
  }
}

@keyframes highlightOnTheWay {
  0% {
    background: rgba(255, 184, 51, 0.7) !important;
  }

  50% {
    background: transparent;
  }

  100% {
    background: rgba(255, 184, 51, 0.7) !important;
  }
}
</style>
