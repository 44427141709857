<script>
import { Bar } from "vue-chartjs";

export default {
  extends: Bar,

  props: {
    data: {
      type: Array,
      required: false
    },
    stayedIdxs: {
      type: Array,
      required: false
    },
    tripIdxs: {
      type: Array,
      required: false
    },
    changeValue: {
      type: Boolean,
      required: false
    },
    trip: {
      type: Array,
      required: false
    },
    stayed: {
      type: Array,
      required: false
    },
    labels: {
      type: Array,
      required: true
    },
    tagLabels: {
      type: Array,
      required: false
    },
    yLabel: {
      type: String
    },
    xLabel: {
      type: String
    },
    days: {
      type: Number
    }
  },

  data() {
    return {
      labelString: this.yLabel,
      type: "bar",
      chartdata: {
        filtered: false,
        labels: [],
        datasets: [
          {
            label: "Dataset 1",
            backgroundColor: "#1F4258",
            data: null,
            minBarLength: 10
          },
          {
            label: "Dataset 2",
            backgroundColor: "#788D9A",
            data: null,
            minBarLength: 10
          }
        ]
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: {
          display: false
        },
        tooltips: {
          callbacks: {
            label: tooltipItem =>
              this.formatTooltipValue(tooltipItem.value, tooltipItem),
            footer: footerItem => this.formatFooterValue(footerItem)
          }
        },
        scales: {
          yAxes: [
            {
              display: true,
              stacked: true,
              scaleLabel: {
                display: true,
                labelString: "Minutes"
              },
              ticks: {
                min: this.setMinValue(),
                precision: 0,
                callback: value => this.formatLabelYAxes(value),
                stepSize: this.yLabel === "Quantity" ? 1 : 60
              }
            }
          ],
          xAxes: [
            {
              display: true,
              stacked: true,
              gridLines: {
                display: false
              },
              scaleLabel: {
                display: true,
                labelString: this.xLabel || "Route order"
              }
            }
          ]
        }
      }
    };
  },

  watch: {
    data: {
      deep: true,
      handler() {
        this.render();
      }
    },
    labels: {
      deep: true,
      handler() {
        this.render();
      }
    }
  },

  methods: {
    formatFooterValue(footer) {
      if (this.tagLabels) return this.tagLabels[footer[0].index];
    },
    formatTooltipValue(time, item) {
      if (this.changeValue) {
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        const seconds = Number((time % 3600) % 60).toFixed(0);
        return (
          (item.datasetIndex === 0 ? "Stayed: " : "Travelling: ") +
          `${hours >= 1 ? hours + "h:" : ""}${minutes}m:${
            seconds < 10 ? "0" + seconds : seconds
          }s`
        );
      }

      if (this.yLabel === "Quantity") {
        return (
          this.yLabel +
          ": " +
          time +
          " - " +
          (time / this.days).toFixed(1) +
          " p/ day"
        );
      }

      if (this.yLabel) return this.yLabel + ": " + time;

      if (time <= 60) {
        return "Time: " + `${Number(time).toFixed(0)}s`;
      }

      const hours = Math.floor(time / 3600);
      const minutes = Math.floor((time % 3600) / 60);
      const seconds = Number((time % 3600) % 60).toFixed(0);
      return (
        "Time: " +
        `${hours >= 1 ? hours + "h:" : ""}${minutes}m:${
          seconds < 10 ? "0" + seconds : seconds
        }s`
      );
    },

    setMinValue() {
      if (this.xLabel === "Hour Interval (hh:mm)" && this.yLabel !== "Quantity")
        return 0.8 * Math.min(...this.data);
      return 0;
    },

    formatLabelYAxes(time, showSeconds = false) {
      if (this.yLabel) return time;

      if (this.labelString === "Seconds") {
        return time;
      }

      return Math.ceil(time / 60);
    },

    render() {
      if (this.stayed != undefined) {
        this.chartdata.datasets[0].data = Array.from(this.stayed, item =>
          item ? item : null
        );
        this.chartdata.datasets[1].data = Array.from(this.trip, item =>
          item ? item : null
        );
      } else
        this.chartdata.datasets[0].data = Array.from(this.data, item =>
          item ? item : null
        );
      this.chartdata.labels = this.labels;
      this.renderChart(this.chartdata, this.options);
    }
  },

  mounted() {
    if (!this.yLabel) {
      this.labelString = "Time (min)";
    } else {
      this.labelString = this.yLabel;
    }
    this.options.scales.yAxes[0].scaleLabel.labelString = this.labelString;

    this.render();
  }
};
</script>
