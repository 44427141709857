var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"anchor-sorter"},[_c('span',{staticClass:"anchor-sorter__label"},[_c('b',[_vm._v("Gateway")]),_c('span',{staticClass:"sort-icon",on:{"click":function($event){$event.stopPropagation();return _vm.sortAnchors('label')}}},[_c('i',{class:[
          'el-icon-sort-down',
          _vm.anchorOrder.labelAsc === false && _vm.anchors.length > 1
            ? 'template-bold'
            : ''
        ]}),_c('i',{class:[
          'el-icon-sort-up',
          _vm.anchorOrder.labelAsc === true && _vm.anchors.length > 1
            ? 'template-bold'
            : ''
        ]})])]),_c('span',{staticClass:"anchor-sorter__number-tags"},[_c('b',[_vm._v("Quantity")]),_c('span',{staticClass:"sort-icon",on:{"click":function($event){$event.stopPropagation();return _vm.sortAnchors('tags')}}},[_c('i',{class:[
          'el-icon-sort-down',
          _vm.anchorOrder.tagsAsc === false && _vm.anchors.length > 1
            ? 'template-bold'
            : ''
        ]}),_c('i',{class:[
          'el-icon-sort-up',
          _vm.anchorOrder.tagsAsc === true && _vm.anchors.length > 1
            ? 'template-bold'
            : ''
        ]})])])])
}
var staticRenderFns = []

export { render, staticRenderFns }