<template>
  <div>
    <template v-if="battery === 0">
      <img src="@/assets/battery_dead.svg" alt="no battery" />
    </template>
    <template v-else-if="battery === 3">
      <img src="@/assets/battery-full.svg" alt="full battery" />
    </template>
    <template v-else-if="battery === 2">
      <img src="@/assets/battery-high.svg" alt="high battery" />
    </template>
    <template v-else-if="battery === 1">
      <img src="@/assets/battery-medium.svg" alt="medium battery" />
    </template>
    <template v-else>
      <hr />
    </template>
  </div>
</template>

<script>
export default {
  props: {
    battery: {
      type: Number,
      default: null
    }
  }
};
</script>

<style lang="scss" scoped></style>
