import Vue from "vue";
import store from "@/store";
import VueRouter, { RouteConfig } from "vue-router";
import SessionLogin from "@/views/SessionLogin.vue";
import LiveMap from "@/views/LiveMap.vue";
import CargoCheck from "@/views/CargoCheck.vue";
import InventoryManager from "@/views/InventoryManager.vue";
import AssetManager from "@/views/AssetManager.vue";
import PredictionsFullscreen from "@/views/PredictionsFullscreen.vue";
import UserSettings from "@/views/UserSettings.vue";
import StatsLaps from "@/views/StatsLaps.vue";
import StatsLapsDetails from "@/views/StatsLapsDetails.vue";
import StatsGlobals from "@/views/StatsGlobals.vue";
import PredictionsGlobal from "@/views/PredictionsGlobal.vue";
import UserAccountChangePassword from "@/components/user-settings/UserAccountChangePassword.vue";
import ForgotPassword from "@/views/ForgotPassword.vue";
import * as routerTypes from "@/router/router.types";
import * as actionTypes from "@/store/action-types";
import LiveGlobalMap from "@/views/LiveGlobalMap.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: routerTypes.ROUTE_SESSION_LOGIN,
    component: SessionLogin,
    meta: {
      title: "Azitek | SessionLogin"
    }
  },
  {
    path: "/dashboard/:anchorId?",
    name: routerTypes.ROUTE_DASHBOARD,
    component: LiveMap,
    meta: {
      requiresAuth: true,
      title: "Azitek | Dashboard"
    }
  },
  {
    path: "/cargo-check",
    name: routerTypes.ROUTE_CARGO_CHECK,
    component: CargoCheck,
    meta: {
      requiresAuth: true,
      requiresClientTypes: [1],
      title: "Azitek | Cargo Check"
    }
  },
  {
    path: "/inventory-manager",
    name: routerTypes.ROUTE_INVENTORY_MANAGER,
    component: InventoryManager,
    meta: {
      requiresAuth: true,
      requiresClientTypes: [3],
      title: "Azitek | Inventory Manager"
    }
  },
  {
    path: "/asset-manager",
    name: routerTypes.ROUTE_ASSET_MANAGER,
    component: AssetManager,
    meta: {
      requiresAuth: true,
      requiresClientTypes: [4],
      title: "Azitek | Asset Manager"
    }
  },
  {
    path: "/updatepassword/:token?",
    name: routerTypes.ROUTE_FORGOT_PASSWORD,
    component: ForgotPassword,
    meta: {
      title: "Azitek | Forgot Password"
    }
  },
  {
    path: "/analytics/:anchorId/globals",
    name: routerTypes.ROUTE_STATS_GLOBALS,
    component: StatsGlobals,
    meta: {
      requiresAuth: true,
      title: "Azitek | Globals Analytics"
    }
  },
  {
    path: "/analytics/predictions",
    name: routerTypes.ROUTE_PREDICTIONS_GLOBAL,
    component: PredictionsGlobal,
    meta: {
      requiresAuth: true,
      title: "Azitek | Global Predictions"
    }
  },
  {
    path: "/analytics/:anchorId?",
    name: routerTypes.ROUTE_STATS_LAPS,
    component: StatsLaps,
    meta: {
      requiresAuth: true,
      title: "Azitek | Laps Analytics"
    },
    children: [
      {
        path: "route/:routeId?",
        name: routerTypes.ROUTE_STATS_LAPS_DETAILS,
        props: true,
        component: StatsLapsDetails,
        meta: {
          title: "Azitek | Laps Analytics Details"
        }
      }
    ]
  },
  {
    path: "/predictions/:anchorId?",
    name: routerTypes.ROUTE_PREDICTIONS_FULLSCREEN,
    component: PredictionsFullscreen,
    meta: {
      requiresAuth: true,
      title: "Azitek | Predictions"
    }
  },
  {
    path: "/settings/:tab?",
    name: routerTypes.ROUTE_USER_SETTINGS,
    component: UserSettings,
    meta: {
      requiresAuth: true,
      requiresClientTypes: [0, 1, 2, 3, 4],
      title: "Azitek | Settings"
    },
    children: [
      {
        path: "change-password",
        name: routerTypes.ROUTE_USER_CHANGE_PASSWORD,
        component: UserAccountChangePassword,
        meta: {
          title: "Azitek | Settings",
          requiresClientTypes: [0, 1, 2, 3, 4]
        }
      }
    ]
  },
  {
    path: "/live-global-map/:anchorId?",
    name: routerTypes.ROUTE_LIVE_GLOBAL_MAP,
    component: LiveGlobalMap,
    meta: {
      requiresAuth: true,
      title: "Azitek | Live Global Map",
      requiresClientTypes: [0]
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

function getRouteByClientType(clientType: number | null) {
  switch (clientType) {
    case 1:
      return { name: routerTypes.ROUTE_CARGO_CHECK };
    case 2:
      return { name: routerTypes.ROUTE_DASHBOARD };
    case 3:
      return { name: routerTypes.ROUTE_INVENTORY_MANAGER };
    case 4:
      return { name: routerTypes.ROUTE_ASSET_MANAGER };
    default:
      return { name: routerTypes.ROUTE_LIVE_GLOBAL_MAP };
  }
}

router.beforeEach(async (to, from, next) => {
  const csrfToken = localStorage.getItem("csrf");
  const isAuthenticated = store.getters["auth/isAuthenticated"];
  const isPrivateRoute = to.matched.some(record => record.meta.requiresAuth);
  const clientType = store.getters["auth/getClientType"];
  const requiredClientTypes = to.meta?.requiresClientTypes || [0, 2];

  // if (from.path === to.path) {
  //   return;
  // }

  if (!csrfToken) {
    // call logout

    if (isPrivateRoute) {
      return next({
        name: routerTypes.ROUTE_SESSION_LOGIN
      });
    } else {
      return next();
    }
  } else if (!isAuthenticated) {
    try {
      const res = await store.dispatch(`auth/${actionTypes.AUTH_SESSION_USER}`);
      const sessionClientType = res.data.clientType;

      if (!requiredClientTypes.includes(sessionClientType)) {
        return next(getRouteByClientType(sessionClientType));
      }
      if (isPrivateRoute) {
        return next();
      } else {
        return next({
          name: routerTypes.ROUTE_SESSION_LOGIN
        });
      }
    } catch (err) {
      localStorage.removeItem("csrf");
      if (isPrivateRoute) {
        return next({
          name: routerTypes.ROUTE_SESSION_LOGIN
        });
      } else {
        return next();
      }
    }
  } else {
    if (!requiredClientTypes.includes(clientType)) {
      return next(getRouteByClientType(clientType));
    }
    if (isPrivateRoute) {
      return next();
    } else {
      return next({
        name: routerTypes.ROUTE_LIVE_GLOBAL_MAP
      });
    }
  }
});

export default router;
