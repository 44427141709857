<template>
  <div class="routes-creator">
    <h3 class="title">Create New Route</h3>
    <div class="select-copy">
      <span class="select-text">Get data from route</span>
      <el-select
        v-model="selectedRouteid"
        placeholder="Select route"
        clearable
        @change="onSelectRoute"
      >
        <el-option
          v-for="route in routeList"
          :key="route.id"
          :label="route.label ?? route.id"
          :value="route.id"
        >
          <span style="float: left"> {{ route.label }}</span>
          <span style="float: left; padding-left: 5px">
            <i
              :class="
                route.constraintTag
                  ? 'el-icon-location-outline'
                  : route.constraintShiftStart != 0 ||
                    route.constraintShiftEnd != 86399
                  ? 'el-icon-time'
                  : ''
              "
            >
            </i>
          </span>
          <span
            style="float: right; font-size: 12px"
            v-if="
              route.constraintShiftStart != 0 ||
                route.constraintShiftEnd != 86399
            "
          >
            {{ formatTime(route.constraintShiftStart) }} -
            {{ formatTime(route.constraintShiftEnd) }}
          </span>
          <span
            style="float: right; font-size: 12px"
            v-else-if="route.constraintTag"
          >
            {{ route.constraintTag }}
          </span>
        </el-option>
      </el-select>
    </div>
    <div class="form" v-loading.lock="loading">
      <div class="form__table__form">
        <h3>Settings</h3>
        <el-form
          label-position="left"
          class="route-form"
          ref="routeForm"
          :rules="rules"
          :model="routeForm"
        >
          <el-form-item
            label="Route Label"
            class="route-label form-flex"
            prop="routeLabel"
          >
            <el-input
              v-model="routeForm.routeLabel"
              class="route-label-input"
              :disabled="!isAdmin"
              clearable
              @input="setIsChanging(true)"
              size="small"
            ></el-input>
          </el-form-item>
          <el-form-item label="Target Route Duration (HH:mm)" class="form-flex">
            <el-time-picker
              v-model="goalTime"
              :disabled="!isAdmin"
              style="width: 130px"
              placeholder="Goal Time"
              :picker-options="{
                format: 'HH:mm'
              }"
              size="small"
              format="HH:mm"
              value-format="HH:mm"
              @change="setIsChanging(true)"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="Route Timeout (HH:mm)" class="form-flex">
            <el-time-picker
              v-model="routeTimeout"
              :disabled="!isAdmin"
              style="width: 130px"
              placeholder="Route Timeout"
              :picker-options="{
                format: 'HH:mm'
              }"
              size="small"
              format="HH:mm"
              value-format="HH:mm"
              @change="setIsChanging(true)"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="Min Route Duration (HH:mm)" class="form-flex">
            <el-time-picker
              v-model="routeMinDuration"
              :disabled="!isAdmin"
              style="width: 130px"
              placeholder="Route Duration"
              :picker-options="{
                format: 'HH:mm'
              }"
              size="small"
              format="HH:mm"
              value-format="HH:mm"
              @change="setIsChanging(true)"
            >
            </el-time-picker>
          </el-form-item>
          <el-form-item label="Route Position Tolerance" class="form-flex">
            <el-checkbox
              border
              :disabled="!isAdmin"
              v-model="skipsEnabledCheckbox"
              size="medium"
              type="primary"
              style="width: 130px"
              @change="setIsChanging(true)"
            >
              Enabled
            </el-checkbox>
          </el-form-item>
          <el-form-item label="Start Route On Beacon" class="form-flex">
            <el-switch
              class="switch"
              :disabled="!isAdmin"
              v-model="startOnEnter"
              active-color="#1F4258"
              inactive-color="#1F4258"
              active-text="Arrival"
              inactive-text="Departure"
              @change="startOnEnterChange()"
            >
            </el-switch>
          </el-form-item>
          <el-form-item label="Finish Route On Beacon" class="form-flex">
            <el-switch
              class="switch"
              v-model="finishOnEnter"
              active-color="#1F4258"
              inactive-color="#1F4258"
              active-text="Arrival"
              :disabled="disableSwitch || !isAdmin"
              inactive-text="Departure"
              @change="setIsChanging(true)"
            >
            </el-switch>
          </el-form-item>
        </el-form>
      </div>
      <div class="route-constraints">
        <div class="route-constraints__header">
          <h3>Constraints</h3>
          <el-tooltip
            placement="top"
            content="Route constraints let you set specific conditions for your route, such as decision beacon or time-based work shift limits."
          >
            <i class="el-icon-info"></i>
          </el-tooltip>
        </div>
        <el-form
          label-position="left"
          class="route-form"
          :rules="rules"
          ref="routeForm"
          :model="routeForm"
        >
          <el-form-item class="form-flex flex-wrap">
            <template slot="label">
              <!-- <el-tooltip placement="left" content="Set a decision beacon as a reference point for the current route in progress.">                 -->
              <el-tooltip placement="left" content="Coming soon">
                <span
                  >Decision Beacon <i class="el-icon-location-outline"></i
                ></span>
              </el-tooltip>
              <!-- </el-tooltip> -->
            </template>
            <el-select
              v-model="decisionBeacon"
              placeholder="Select beacon"
              :disabled="true"
              filterable
              size="small"
              clearable
              @change="setIsChanging(true)"
            >
              <el-option
                v-for="tag in resources.data.tags.filter(
                  tag => tag.active && tag.lastPosition != null
                )"
                :key="`tag.label${tag.tagId}`"
                :label="
                  `(${tag.tagId
                    .toString(16)
                    .toUpperCase()
                    .match(/.{1,2}/g)
                    .join('')}) ${tag.label}`
                "
                :value="tag.lastPosition.id"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="form-flex flex-wrap">
            <template slot="label">
              <el-tooltip
                placement="left"
                content="Set a work shift interval for starting this route."
              >
                <span>Work Shift <i class="el-icon-time"></i></span>
              </el-tooltip>
            </template>
            <el-time-picker
              is-range
              size="small"
              :disabled="decisionBeacon != ''"
              v-model="routeShift"
              range-separator="To"
              start-placeholder="Start time"
              end-placeholder="End time"
              format="HH:mm"
              value-format="HH:mm"
              @change="setIsChanging(true)"
            >
            </el-time-picker>
          </el-form-item>
        </el-form>
      </div>
      <div class="route-suggestion">
        <h3>Creation Assistant (BETA)</h3>
        <el-form label-position="left" class="route-suggestion__form flex-wrap">
          <el-form-item label="Gateway">
            <el-select
              v-model="targetAnchorId"
              placeholder="Select gateway"
              filterable
              size="small"
              class="search-input"
              @change="setIsChanging(true)"
            >
              <el-option
                v-for="anchor in resources.data.anchors"
                :key="anchor.id"
                :label="anchor.label"
                :value="anchor.anchorId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="Starting Beacon">
            <el-select
              v-model="startingBeacon"
              placeholder="Select beacon"
              filterable
              size="small"
              class="search-input"
              @change="setIsChanging(true)"
            >
              <el-option
                v-for="tag in resources.data.tags.filter(
                  tag =>
                    tag.active &&
                    tag.lastPosition != null &&
                    tag.parentId == null
                )"
                :key="`tag.label${tag.tagId}`"
                :label="
                  `(${tag.tagId
                    .toString(16)
                    .toUpperCase()
                    .match(/.{1,2}/g)
                    .join('')}) ${tag.label}`
                "
                :value="tag.lastPosition.id"
              ></el-option>
            </el-select>
          </el-form-item>
        </el-form>
        <el-tooltip
          class="item"
          effect="dark"
          content="Revert back to empty beacon list."
          placement="bottom"
        >
          <el-button
            v-if="isAdmin"
            size="mini"
            plain
            :disabled="!hasBeenGenerated"
            @click="revertToActiveRoute()"
          >
            Revert
          </el-button>
        </el-tooltip>
        <el-tooltip
          class="item"
          effect="dark"
          content="Let Azitek suggest a route for you using data from previous journeys! Just make sure to have a gateway and a starting beacon selected"
          placement="bottom"
        >
          <el-button
            v-if="isAdmin"
            size="mini"
            plain
            :disabled="
              startingBeacon == null || hasBeenGenerated || !targetAnchorId
            "
            @click="fetchMostCommonRoute()"
          >
            Generate
          </el-button>
        </el-tooltip>
      </div>
      <div class="form__table__edit-route">
        <h3 class="beacon-list-title">Beacons List</h3>
        <span class="required-field" v-if="isTableDataEmpty"
          >Please select beacons</span
        >
        <el-table-draggable @drop="handleDrop">
          <el-table
            :data="tableData"
            :row-class-name="getTableRowClassName"
            v-loading="loadingTable"
          >
            <el-table-column :resizable="false" label="Order" width="75">
              <template slot-scope="scope">
                {{ scope.row.ord }}
              </template>
            </el-table-column>
            <el-table-column :resizable="false" label="ID" width="100">
              <template slot-scope="scope">
                <template v-if="scope.row.tagId">
                  {{
                    scope.row.tagId
                      .toString(16)
                      .toUpperCase()
                      .match(/.{1,2}/g)
                      .join("")
                  }}
                </template>
              </template>
            </el-table-column>
            <el-table-column :resizable="false" label="Label" prop="label">
              <template slot-scope="scope">
                <template v-if="scope.row.label">
                  {{ scope.row.label }}
                </template>
              </template>
            </el-table-column>
            <el-table-column :resizable="false" width="50">
              <template slot-scope="scope">
                <div @click="handleDelete(scope.$index, scope.row)">
                  <i class="el-icon-delete" />
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-table-draggable>
        <div class="form__table__settings-buttons-bottom">
          <el-button
            v-if="isAdmin"
            size="mini"
            plain
            style="width: 125px"
            @click="clearAllRoutes()"
          >
            Clear All
          </el-button>
          <div class="download-container">
            <div class="route-information">
              <div>
                <el-button
                  type="success"
                  size="mini"
                  @click="downloadCSV(false)"
                  >Download Beacons List</el-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="form-buttons">
      <el-button v-if="isAdmin" size="small" @click="setDialogDiscard(true)">
        Cancel
      </el-button>
      <el-button
        v-if="isAdmin"
        type="primary"
        size="small"
        @click="makeSureSave()"
      >
        Save
      </el-button>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from "vuex";
import * as actionTypes from "@/store/action-types";
import ElTableDraggable from "element-ui-el-table-draggable";
import { cloneDeep } from "lodash";
import RoutesApi from "@/modules/routes/api/routes.api";
import moment from "moment";

export default {
  name: "RoutesCreator",

  components: {
    ElTableDraggable
  },

  props: {
    routeList: {
      type: Array,
      default: () => []
    },
    tableData: {
      type: Array,
      default: () => []
    },
    creatingNewRoute: {
      type: Boolean,
      default: false
    },
    discardCreateRoute: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      finishOnEnter: true,
      foundRoute: [],
      goalTime: "01:00",
      routeShift: [
        moment.utc(0).format("HH:mm"),
        moment.utc(86399 * 1000).format("HH:mm")
      ],
      hasBeenGenerated: false,
      isTableDataEmpty: false,
      loading: true,
      loadingTable: false,
      routeForm: {
        routeLabel: ""
      },
      routeMinDuration: "02:00",
      routeSchedule: "",
      routeTimeout: "02:00",
      rules: {
        routeLabel: [
          {
            required: true,
            message: "Please input route label",
            trigger: "blur"
          },
          {
            min: 3,
            max: 50,
            message: "Length should be 3 to 50",
            trigger: "blur"
          }
        ]
      },
      selectedRouteid: "",
      skipsEnabledCheckbox: true,
      startingBeacon: null,
      decisionBeacon: "",
      startOnEnter: true,
      targetAnchorId: null
    };
  },

  computed: {
    ...mapState("auth", {
      user: "user"
    }),
    ...mapState("user", {
      resources: "resources",
      isChangingInformation: "isChangingInformation"
    }),

    disableSwitch() {
      if (this.tableData.length > 0)
        if (
          this.tableData[0].tagId ===
          this.tableData[this.tableData.length - 1].tagId
        )
          if (this.startOnEnter) return true;
      return false;
    },

    ...mapGetters("auth", ["isAdmin"])
  },

  watch: {
    tableData: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue.length > 0)
          if (
            newValue[0].id === newValue[newValue.length - 1].id &&
            this.startOnEnter
          )
            this.finishOnEnter = true;
        if (newValue.length === 0 && !this.hasBeenGenerated)
          this.startingBeacon = null;
        else if (newValue.length) this.startingBeacon = newValue[0].id;
      }
    },

    creatingNewRoute: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue === true) {
          this.createNewRoute();
        }
      }
    },

    discardCreateRoute: {
      deep: true,
      immediate: true,
      handler(newValue) {
        if (newValue === true) {
          this.handleClearRoute();
          this.routeMinDuration = "02:00";
          this.finishOnEnter = true;
          this.goalTime = "01:00";
          this.routeShift = [
            moment.utc(0).format("HH:mm"),
            moment.utc(86399 * 1000).format("HH:mm")
          ];
          this.routeMinDuration = "02:00";
          this.routeSchedule = "";
          this.routeTimeout = "02:00";
          this.skipsEnabledCheckbox = true;
          this.startingBeacon = null;
          this.decisionBeacon = "";
          this.startOnEnter = true;
          this.targetAnchorId = "";
          this.$emit("update-discard-create-route");
        }
      }
    }
  },

  methods: {
    ...mapActions("user", {
      setIsChanging: actionTypes.USER_SET_IS_CHANGING,
      setDiscard: actionTypes.USER_SET_DISCARD,
      setPath: actionTypes.USER_SET_PATH
    }),

    startOnEnterChange() {
      if (this.tableData.length > 0)
        if (
          this.tableData[0].tagId ===
          this.tableData[this.tableData.length - 1].tagId
        )
          if (this.startOnEnter) this.finishOnEnter = true;
      this.setIsChanging(true);
    },

    getTableRowClassName({ row, rowIndex }) {
      if (row.color) return "table-data__yellow";
      else return "table-data";
    },

    async fetchMostCommonRoute() {
      try {
        this.loadingTable = true;
        const tagPositions = await RoutesApi.fetchMostCommonRoute(
          this.targetAnchorId,
          this.startingBeacon
        );
        this.hasBeenGenerated = true;
        this.clearAllRoutes();
        if (
          !tagPositions?.data?.length ||
          typeof tagPositions.data === "string"
        ) {
          return;
        }

        const activeTags = this.resources.data.tags.filter(
          tag => tag.active && tag.lastPosition != null
        );

        const newTableData = this.tableData;

        tagPositions.data.forEach(tagPosition => {
          activeTags.forEach(tag => {
            if (tag.lastPosition.id === tagPosition)
              newTableData.push({
                id: tag.id,
                tagId: tag.tagId,
                label: tag.label,
                tagPositionId: tagPosition,
                ord: this.tableData.length + 1,
                color: "yellow"
              });
          });
        });

        this.$emit("change-table-data", newTableData);
        if (newTableData.length) {
          this.setIsChanging(true);
        }
      } catch {
        this.$notify.error({
          title: "Error",
          message: "Something went wront while trying to generate beacon list."
        });
      } finally {
        this.loadingTable = false;
        this.isTableDataEmpty = false;
      }
    },

    revertToActiveRoute() {
      this.$emit("change-table-data", []);
      this.hasBeenGenerated = false;
      this.setDiscard(false);
      this.setPath("");
      this.setIsChanging(false);
    },

    handleDrop(drop) {
      const newTableData = drop.list.map((entry, index) => {
        return {
          ...entry,
          ord: index + 1
        };
      });
      this.$emit("change-table-data", newTableData);
      this.startingBeacon = this.tableData[0].tagPositionId;
    },

    downloadCSV() {
      const newArray = [];
      this.tableData.forEach(element => {
        newArray.push([
          element.ord,
          "0x" +
            element.tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join(""),
          element.label
        ]);
      });
      let csvContent = "Ord, Tag ID, Label \n";
      newArray.forEach(function(infoArray, index) {
        const dataString = infoArray.join(",");
        csvContent += dataString + "\n";
      });
      const anchor = document.createElement("a");
      anchor.href =
        "data:text/csv;charset=utf-8," + encodeURIComponent(csvContent);
      anchor.target = "_blank";
      anchor.download = "beacons-list.csv";
      anchor.click();
    },

    makeSureSave() {
      this.$refs.routeForm.validate(valid => {
        if (!this.tableData.length) {
          this.isTableDataEmpty = true;
        } else {
          this.isTableDataEmpty = false;
        }
        if (
          !valid ||
          !this.tableData.length ||
          this.routeForm.routeLabel === ""
        ) {
          this.$notify.error({
            title: "Error",
            message: "Please fill in all required fields before saving."
          });
          this.setDialogSave(false);
          return false;
        } else {
          this.setDialogSave(true);
          return true;
        }
      });
    },

    handleDelete(index) {
      let newTableData = this.tableData.slice();
      newTableData.splice(index, 1);
      const tempArray = cloneDeep(newTableData);

      tempArray
        .slice(index)
        .forEach(element => (element.ord = element.ord - 1));
      newTableData = cloneDeep(tempArray);

      this.$emit("change-table-data", newTableData);

      this.setIsChanging(true);
    },

    async createNewRoute() {
      let payload = {};
      const newRouteTimeout = moment.duration(this.routeTimeout).asSeconds();
      const newRouteMinDuration = moment
        .duration(this.routeMinDuration)
        .asSeconds();
      const newGoalTime = moment.duration(this.goalTime).asSeconds();
      let startTag = 1;
      let endTag = 1;
      let tags = [];

      if (this.tableData.length !== 0) {
        startTag = this.tableData[0].id;
        endTag = this.tableData[this.tableData.length - 1].id;
        tags = this.tableData.map((entry, index) => ({
          ord: index + 1,
          tagPositionId: entry.tagPositionId
        }));
      }

      let startSeconds = 0;
      let endSeconds = 86399;

      if (
        this.routeShift != null &&
        (this.routeShift[0] != "00:00" || this.routeShift[1] != "23:59")
      ) {
        startSeconds =
          moment(this.routeShift[0], "HH:mm").diff(
            moment().startOf("day"),
            "seconds"
          ) + 1;
        endSeconds =
          moment(this.routeShift[1], "HH:mm").diff(
            moment().startOf("day"),
            "seconds"
          ) + 59;
      }

      payload = {
        label: this.routeForm.routeLabel,
        startTag: startTag,
        endTag: endTag,
        tags: tags,
        skipsMismatches: this.skipsEnabledCheckbox,
        startOnEnter: this.startOnEnter,
        finishOnEnter: this.finishOnEnter,
        routeTimeout: newRouteTimeout,
        routeMinDuration: newRouteMinDuration,
        goalTime: newGoalTime,
        constraintTag: this.decisionBeacon != "" ? this.decisionBeacon : null,
        constraintShiftStart: startSeconds,
        constraintShiftEnd: endSeconds
      };

      this.$emit("change-table-data", cloneDeep(this.tableData));
      this.loading = true;
      try {
        const res = await RoutesApi.createAnchorRoute(payload);
        if (res.data) {
          this.handleClearRoute();
          this.$emit("fetch-route-list");
          this.$notify({
            title: "Success",
            message: "Successfully created route.",
            type: "success"
          });
        }
      } catch (error) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      } finally {
        this.loading = false;
        this.$emit("update-creating-new-route");
        this.setIsChanging(false);
      }
    },

    clearAllRoutes() {
      this.$emit("change-table-data", []);
      this.setIsChanging(false);
      this.$forceUpdate();
    },

    handleClearRoute() {
      this.selectedRouteid = "";
      this.isTableDataEmpty = false;
      this.routeForm.routeLabel = "";
      this.goalTime = "";
      this.routeTimeout = "";
      this.routeMinDuration = "";
      this.$emit("change-table-data", []);
    },

    setDialogSave(booleanValue) {
      this.$emit("set-dialog-save", booleanValue);
    },

    setDialogDiscard(booleanValue) {
      if (this.isChangingInformation) {
        this.$emit("set-dialog-discard", booleanValue);
      }
    },

    onSelectRoute() {
      const foundRoute = this.routeList.find(
        route => route.id === this.selectedRouteid
      );

      if (foundRoute) {
        this.goalTime = moment.utc(foundRoute.goalTime * 1000).format("HH:mm");
        this.routeTimeout = moment
          .utc(foundRoute.routeTimeout * 1000)
          .format("HH:mm");
        this.routeMinDuration = moment
          .utc(foundRoute.routeMinDuration * 1000)
          .format("HH:mm");
        (this.routeShift = [
          moment.utc(foundRoute.constraintShiftStart * 1000).format("HH:mm"),
          moment.utc(foundRoute.constraintShiftEnd * 1000).format("HH:mm")
        ]),
          (this.skipsEnabledCheckbox =
            foundRoute.routeTolerance != 0 ? true : false);

        const newTableData = [];

        foundRoute.tags.forEach(tag => {
          const tagInfo = this.resources.data.tags.find(
            t => t.lastPosition.id == tag.lastPosition.id
          );
          if (tagInfo) {
            newTableData.push({
              id: tagInfo.id,
              tagId: tagInfo.tagId,
              label: tagInfo.label,
              tagPositionId: tagInfo.lastPosition.id,
              ord: tag.ord
            });
          }
        });
        this.setIsChanging(true);
        this.$emit("change-table-data", newTableData);
      }
    },

    formatTime(targetTime) {
      if (targetTime != null) {
        return moment.utc(targetTime * 1000).format("HH:mm");
      } else return "--";
    }
  },

  async created() {
    this.loading = false;

    this.setIsChanging(false);
  }
};
</script>

<style lang="scss">
.routes-creator {
  padding-top: 15px;

  .select-copy {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 40px;
    padding-bottom: 20px;
    padding-top: 12px;
    gap: 10px;
    border-bottom: 1px solid grey;

    @media (max-width: 636px) {
      margin: 0 20px;
      justify-content: center;
    }

    .select-text {
      text-align: left;
      text-transform: uppercase;
      font-weight: 700;
      font-size: 12px;
      color: #606266;
    }

    .el-select {
      width: 300px;

      @media (max-width: $xs) {
        width: 70vw;
      }

      .el-input--suffix .el-input__inner {
        height: 32px;
        font-size: 12px;
      }
      .el-input {
        height: 32px;
      }
      .el-input .el-input--suffix .el-input__suffix {
        top: 5px;
      }

      .el-input__suffix {
        top: 5px;
      }
    }
  }

  .route-constraints {
    padding-top: 15px;

    .el-select {
      width: 300px;
      border-radius: 5px;

      @media (max-width: $xs) {
        width: 70vw;
      }

      .el-input .el-input--suffix .el-input__suffix {
        top: 5px;
      }
    }

    &__header {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 5px;

      .el-icon-info {
        color: $--color-primary;
      }
    }
  }

  .form {
    padding: 0 40px 15px;
    overflow-y: auto;
    max-height: calc(100vh - 425px);

    @media (max-width: 636px) {
      padding: 0 19px 20px;
    }

    @media (max-width: $md) {
      max-height: 100%;
    }

    .el-form-item__error {
      position: absolute;
    }

    .el-form-item {
      margin-bottom: 0px;

      &::before,
      &::after {
        display: none !important;
      }

      .el-input__inner {
        padding-right: 0 !important;
      }
    }

    .table-data {
      width: 100%;
      max-height: 40vh;
      overflow-y: scroll;

      &__yellow {
        background-color: rgba(255, 184, 51, 0.7);
      }
      &__red {
        border: 1px solid red;
      }
    }
    .red-table {
      border: 1px solid red;
    }

    .download-container {
      display: flex;
      justify-content: center;
    }

    .route-information {
      display: flex;
      justify-content: flex-end;
      width: 94%;

      @media (max-width: 600px) {
        width: auto;
      }
    }

    @media (max-width: 1700px) {
      .route-information {
        display: flex;
        justify-content: flex-end;
        margin-left: 0px;
      }
    }

    .form-flex {
      display: flex;
      justify-content: space-between;

      @media (max-width: $xs) {
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
      }

      .el-icon-location-outline {
        font-size: 15px;
      }
    }

    .flex-wrap {
      flex-wrap: wrap;
    }

    .route-form {
      margin-top: 15px;
      margin-bottom: 15px;

      .route-label-input .el-input__inner {
        height: 32px !important;
        font-size: 12px;
      }

      .route-label-input {
        width: 300px;

        @media (max-width: $sm) {
          width: 200px;
        }
        @media (max-width: $xs) {
          width: 70vw;
        }
      }

      .route-label .el-form-item__label {
        width: max-content !important;
      }

      .el-form-item label.el-form-item__label {
        line-height: 40px;
        width: 210px;
      }
    }

    .target-route-duration {
      display: flex;
      justify-content: space-between;
    }

    .el-form-item__content {
      margin-left: 0px;
    }

    .skips-mismatches {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-top: 3%;

      &__input {
        width: 50%;
        margin-top: 2%;
      }
    }

    .switch {
      margin-top: 10px;
    }

    h4 {
      margin: 24px auto;
    }

    .route-suggestion {
      margin-top: 15px;
      border-top: 1px solid grey;
      padding-top: 15px;

      &__form {
        display: flex;
        justify-content: space-between;
        margin: 15px 0;

        @media (max-width: $sm) {
          flex-wrap: wrap;
        }

        .el-form-item {
          display: flex;
          align-items: center;
          gap: 10px;

          @media (max-width: $sm) {
            justify-content: space-between;
            width: 100%;
          }
        }

        .el-form-item label.el-form-item__label {
          width: auto;
        }
      }
    }

    &__table {
      padding: 0 40px 15px;
      overflow-y: scroll;
      max-height: 55vh;

      @media (max-width: 636px) {
        padding: 0 20px 5px;
      }

      &__form {
        padding-top: 15px;
        border-bottom: 1px solid gray;
      }

      &__edit-route {
        margin-top: 15px;
        border-top: 1px solid grey;
        padding-top: 15px;

        .beacon-list-title::before {
          content: "*";
          color: #f56c6c;
          margin-right: 4px;
        }

        .required-field {
          color: #f56c6c;
          font-size: 12px;
          line-height: 1;
          padding-top: 4px;
          top: 100%;
          left: 0;
        }
      }

      &__settings {
        margin-top: 1vh;
        display: flex;
        justify-content: space-evenly;
      }

      .settings-label {
        display: flex;
        justify-content: left;
        align-items: center;
      }

      &__settings-buttons {
        display: flex;
        justify-content: right;
        margin-top: 15px;
        margin-right: 15px;
      }

      &__settings-buttons-bottom {
        display: grid;
        grid-template-columns: 80% 20%;
        margin-top: 15px;

        @media (max-width: 600px) {
          grid-template-columns: auto;
          gap: 10px;
          justify-content: start;
        }
      }
    }

    .el-table {
      margin-top: 24px;

      .el-table__row td:first-child .cell {
        line-height: 28px;
      }
    }

    .el-table__body td {
      cursor: pointer;
    }
  }

  .form-buttons {
    border-top: 1px solid grey;
    padding: 20px 0;
    margin: 0 40px;

    @media (max-width: 636px) {
      margin: 0 20px;
    }
  }
}
</style>
