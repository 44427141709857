<template>
  <div class="live-global-map">
    <LiveMap
      :show-anchors="true"
      :anchorsToShow="anchorsToShow"
      :interval-update="true"
      :tagToShowOnMap="tagToShowOnMap"
      :class="{ 'no-layout-image': !clientData.mapImages.length }"
    />
    <div class="anchors" v-if="clientData.mapImages.length">
      <div class="anchor" v-for="anchor in anchors" :key="anchor.id">
        <GatewayInfo
          :anchor="anchor"
          :routeList="routeList"
          @update-anchors-to-show="updateAnchorsToShow"
          @update-tag-to-show-on-map="updateTagToShowOnMap"
        />
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { cloneDeep } from "lodash";
import { mapState, mapActions } from "vuex";
import * as actionTypes from "@/store/action-types";
import AnchorsApi from "@/modules/anchors/api/anchors.api";
import RoutesApi from "@/modules/routes/api/routes.api";
import GatewayInfo from "@/components/live-global-map/LiveGlobalGatewayInfo.vue";
import LiveMap from "@/views/LiveMap.vue";

export default {
  name: "LiveGlobalMap",

  components: {
    GatewayInfo,
    LiveMap
  },
  computed: {
    ...mapState("user", {
      resources: "resources"
    }),
    ...mapState("clients", {
      clientData: "data"
    })
  },
  data() {
    return {
      anchors: [],
      anchorsToShow: [],
      colors: [
        "#32CD32",
        "#0A75AD",
        "#151517",
        "#CDC115",
        "#5B099F",
        "#FF0033",
        "#CC8400"
      ],
      routeList: [],
      tagToShowOnMap: {}
    };
  },
  methods: {
    ...mapActions("clients", {
      updateGlobalView: actionTypes.CLIENTS_UPDATE_GLOBAL_VIEW
    }),
    ...mapActions("user", {
      getResources: actionTypes.USER_GET_RESOURCES,
      getUsageInfo: actionTypes.USER_GET_USAGE_INFO
    }),
    async getNextPosition(anchorId) {
      try {
        const res = await AnchorsApi.getNextPosition(anchorId);
        return res;
      } catch (err) {
        console.log(err);
      }
    },
    checkAnchorOffline(powerSource, offlineTs) {
      if (!offlineTs) return false;
      else {
        const sixMinutesAgo = moment()
          .utc()
          .subtract(6, "minutes");

        if (!powerSource) {
          if (moment.utc(moment.unix(offlineTs)).isBefore(sixMinutesAgo)) {
            return true;
          }
          return false;
        } else {
          const oneMinuteAgo = moment()
            .utc()
            .subtract(60, "seconds");

          if (moment.utc(moment.unix(offlineTs)).isBefore(oneMinuteAgo)) {
            return true;
          }
        }
      }
      return false;
    },
    updateAnchorsToShow(anchorId, action) {
      if (action == "add") {
        const foundSelectedAnchor = this.anchorsToShow.find(
          anchor => anchor[0].anchorId === anchorId
        );
        if (!foundSelectedAnchor) {
          const anchor = this.anchors.find(
            anchor => anchor.anchorId === anchorId
          );
          this.anchorsToShow.push([anchor, anchor.color]);
        }
      } else {
        this.anchorsToShow = this.anchorsToShow.filter(
          anchor => anchor[0].anchorId !== anchorId
        );
      }
    },
    async getLastFive(anchorId, index) {
      try {
        const res = await AnchorsApi.getLastFive(anchorId);
        this.anchors[index].lastFivePositions = res.data.lastFive;
      } catch (error) {
        console.log(error);
      }
    },
    async fetchRouteList() {
      try {
        const res = await RoutesApi.getRouteList();
        this.routeList = res.data;
        this.routeList.sort((a, b) => b.anchors.length - a.anchors.length);
      } catch (error) {
        console.log(error);
      }
    },
    updateTagToShowOnMap(tagData) {
      this.tagToShowOnMap = tagData;
    }
  },
  watch: {
    "resources.globalAnchors": {
      deep: true,
      async handler(newValue) {
        if (newValue.length) {
          this.anchors.forEach((anchor, index) => {
            // if (!anchor.lastFivePositions && !this.checkAnchorOffline(anchor.powerSource, anchor.offlineTs)) {
            //   this.getLastFive(anchor.anchorId, index);
            // }
            newValue.forEach(async newAnchor => {
              if (newAnchor.anchorId === anchor.anchorId) {
                if (
                  (!anchor.lastPositionMetadata && newAnchor.state) ||
                  anchor.lastPositionMetadata.state != newAnchor.state
                ) {
                  this.getLastFive(anchor.anchorId, index);
                }
                this.resources.data.anchors.forEach(value => {
                  if (
                    anchor.anchorId === value.anchorId &&
                    anchor.activeRoute?.id !== newAnchor.activeRouteId
                  ) {
                    anchor.activeRoute = newAnchor.activeRoute;
                    anchor.recentRoutes = value.recentRoutes;
                  }
                });
                this.resources.data.tags.forEach(tag => {
                  if (
                    tag.lastPosition &&
                    tag.lastPosition.id === anchor.tagPositionId
                  )
                    anchor.lastPosition = tag.lastPosition;
                });
                anchor.lastAnchorPositionId = newAnchor.lastAnchorPositionId;
                if (anchor.lastPositionMetadata) {
                  anchor.lastPositionMetadata = {};
                }
                anchor.lastPositionMetadata.state = newAnchor.state;
                anchor.lastPositionMetadata.tagPositionId =
                  newAnchor.tagPositionId;
                anchor.lastPositionMetadata.lastSeenTs = newAnchor.lastSeenTs;
                anchor.tagPositionId = newAnchor.tagPositionId;
                anchor.label = newAnchor.label;
                anchor.rssi = newAnchor.rssi ?? 0;
                anchor.powerSource = newAnchor.powerSource;
                if (anchor.currentRouteInstance)
                  anchor.currentRouteInstance.startDate = newAnchor.startDate;
                anchor.offlineTs = newAnchor.offlineTs;
                anchor.activeRouteOnTime = newAnchor.activeRouteOnTime;
                anchor.positionInCircle = newValue.positionInCircle;
              }
            });
          });
          this.anchors.sort((a, b) => {
            const anchorA = a.label.toUpperCase();
            const anchorB = b.label.toUpperCase();
            if (anchorA < anchorB) {
              return -1;
            }
            if (anchorA > anchorB) {
              return 1;
            }
            return 0;
          });
        }
      }
    },
    "resources.data": {
      deep: true,
      async handler(newValue) {
        if (newValue.anchors.length) {
          this.anchors.forEach(anchor => {
            newValue.anchors.forEach(newElement => {
              if (anchor.anchorId === newElement.anchorId) {
                anchor.activeRoute = newElement.activeRoute;
                anchor.activeRouteOnTime = newElement.activeRouteOnTime;
                anchor.activeRouteStart = newElement.activeRouteStart;
                anchor.lastHeartbeat = newElement.lastHeartbeat;
                anchor.lastPositionMetadata =
                  newElement.lastPositionMetadata ?? {};
                anchor.currentRouteInstance = newElement.currentRouteInstance;
              }
            });

            newValue.tags.forEach(tag => {
              if (
                tag.lastPosition &&
                tag.lastPosition.id === anchor.tagPositionId
              )
                anchor.lastPosition = tag.lastPosition;
            });
          });
        }
      }
    },
    "resources.usageInfo": {
      deep: true,
      async handler(newValue) {
        newValue.forEach(value => {
          this.anchors.forEach(anchor => {
            if (anchor.anchorId === value.anchorId) anchor.usageInfo = value;
          });
        });
      }
    }
  },

  created() {
    this.fetchRouteList();
    this.getUsageInfo(this.clientData.id);
    this.getResources();
    this.anchors = this.resources?.data?.anchors
      ? cloneDeep(this.resources.data.anchors)
      : [];
    this.resources.usageInfo.forEach(value => {
      this.anchors.forEach(anchor => {
        if (anchor.anchorId === value.anchorId) anchor.usageInfo = value;
      });
    });

    this.anchors.forEach((anchor, currIndex) => {
      anchor.color = this.colors[currIndex];

      if (!this.checkAnchorOffline(anchor.powerSource, anchor.offlineTs)) {
        this.anchorsToShow.push([anchor, anchor.color]);
      }
      this.getLastFive(anchor.anchorId, currIndex);
    });
    this.anchors.sort((a, b) => {
      const anchorA = a.label.toUpperCase();
      const anchorB = b.label.toUpperCase();
      if (anchorA < anchorB) {
        return -1;
      }
      if (anchorA > anchorB) {
        return 1;
      }
      return 0;
    });
  }
};
</script>

<style lang="scss">
.live-global-map {
  display: flex;

  @media (max-width: 1800px) {
    flex-direction: column;
    align-items: center;
    gap: 5px;
  }

  .dashboard {
    width: 40%;
    padding-top: 0;

    #image-map {
      height: calc(100vh - 68px);
    }

    @media (max-width: 1800px) {
      width: 100%;
      height: 45vh;

      .dashboard__map-wrapper {
        height: 45vh;

        #image-map {
          height: 45vh;
        }
      }
    }
  }
  .no-layout-image {
    width: 100%;
  }

  @media (max-width: 768px) {
    .dashboard__fit-to-bounds {
      top: 78px;
    }
  }
  @media (max-width: 470px) {
    .dashboard .leaflet-control-zoom.leaflet-bar.leaflet-control {
      top: auto;
    }
    .dashboard__tag-slider {
      top: 138px;
    }
  }

  .anchors {
    display: flex;
    flex-direction: column;
    width: 60%;
    gap: 5px;
    padding: 30px 20px;
    max-height: calc(100vh - 120px);
    overflow-y: auto;
    scrollbar-gutter: stable both-edges;

    @media (max-width: 1800px) {
      width: 100%;
      padding: 0;
      max-height: calc(55vh - 100px);
    }

    .anchor {
      display: flex;
      justify-content: center;
    }
  }
}
</style>
