import { ClientsState } from "@/modules/clients/clients.types";

export default {
  isClientDefault(state: ClientsState): boolean {
    return state.data?.type == 0 || state.data?.type == 2 || false;
  },
  isClientPM(state: ClientsState): boolean {
    return state.data?.type == 2 || false;
  },
  // Inventory Manager clients
  isClientIM(state: ClientsState): boolean {
    return state.data?.type == 3 || false;
  }
};
