var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"fullscreen-wrapper"},[_c('div',{staticClass:"container"},[(_vm.anchors.isEmpty)?_c('h4',[_vm._v(" You must have an anchor to see data on this page. ")]):_vm._e()]),(_vm.anchor)?[_c('div',{staticClass:"container fullscreen-wrapper__details"},[_c('div',{staticClass:"gateway-header"},[_c('div',{staticClass:"gateway-selector"},[_c('h3',[_vm._v("Vehicle")]),_c('el-select',{attrs:{"placeholder":"Select Vehicle"},on:{"change":_vm.updateSelectedAnchor},model:{value:(_vm.selectedAnchorId),callback:function ($$v) {_vm.selectedAnchorId=$$v},expression:"selectedAnchorId"}},_vm._l((_vm.resources.data.anchors),function(anchor){return _c('el-option',{key:anchor.anchorId,attrs:{"label":anchor.label,"value":anchor.anchorId}})}),1)],1),(_vm.resources.data.anchors.some(anchor => anchor.activeRoute))?_c('el-tooltip',{attrs:{"placement":"right","content":"Overall Predictions","hide-after":2000}},[_c('el-button',{attrs:{"size":"mini"},on:{"click":_vm.redirectToGlobalPredictions}},[_c('img',{attrs:{"src":require("@/assets/globe.svg"),"alt":"globe"}})])],1):_vm._e()],1),(_vm.anchor.activeRoute)?_c('div',{staticClass:"fullscreen-wrapper__anchor"},[_c('b',[_vm._v("Label: ")]),_vm._v(" "+_vm._s(_vm.anchor.label)+" "),_c('b',[_vm._v("ID: ")]),_vm._v(" "+_vm._s(_vm.anchor.anchorId .toString(16) .padStart(12, "0") .toUpperCase() .match(/.{1,2}/g) .join(":"))+" "),_c('WifiIcon',{attrs:{"signal":_vm.anchor.rssi,"noConnection":_vm.anchor.lastHeartbeat
              ? _vm.isAnchorConnected(_vm.anchor.lastHeartbeat.createdDate)
              : false}}),(
            _vm.anchor.lastHeartbeat
              ? _vm.isAnchorConnected(_vm.anchor.lastHeartbeat.createdDate)
              : false
          )?_c('span',{staticClass:"anchor__offline"},[_vm._v(" Offline ")]):_vm._e()],1):_vm._e(),_c('div',{staticClass:"fullscreen-wrapper__options"},[(_vm.anchor.activeRoute)?[_c('div',{staticClass:"fullscreen-wrapper__options--left"},[_c('p',[_vm._v("Table top entry:")]),_c('el-checkbox',{attrs:{"border":""},on:{"change":_vm.setData},model:{value:(_vm.basedOnAnchorPosition),callback:function ($$v) {_vm.basedOnAnchorPosition=$$v},expression:"basedOnAnchorPosition"}},[_vm._v("based on anchor position")]),_c('el-select',{attrs:{"placeholder":"based on tag"},model:{value:(_vm.tagOnTopOrd),callback:function ($$v) {_vm.tagOnTopOrd=$$v},expression:"tagOnTopOrd"}},_vm._l((_vm.selectData),function(tag){return _c('el-option',{key:`(${tag.ord}) ${tag.label || tag.tagId}`,attrs:{"label":`(${tag.ord}) ${tag.label || tag.tagId}`,"value":tag.ord || ''}})}),1)],1),_c('div',{staticClass:"fullscreen-wrapper__options--right"},[_c('p',[_vm._v("Number of elements in table:")]),_c('el-input-number',{attrs:{"min":1,"max":Math.min(_vm.anchor.activeRoute.tags.length, 10)},on:{"change":_vm.setTableData},model:{value:(_vm.numberOfRows),callback:function ($$v) {_vm.numberOfRows=$$v},expression:"numberOfRows"}}),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.toggle}},[_c('i',{staticClass:"el-icon-full-screen"}),_vm._v(" Go Fullscreen")])],1)]:_c('h4',[_vm._v(" This gateway doesn't have an active route. Go to the "),_c('router-link',{attrs:{"to":{
              name: 'user-settings',
              params: { tab: 'routes', anchorId: _vm.$route.params.anchorId }
            }}},[_vm._v("routes tab")]),_vm._v(" to configure one. ")],1)],2),(_vm.anchor.activeRoute)?_c('fullscreen',{ref:"predictions-fullscreen-mode",staticClass:"fullscreen__on",on:{"change":_vm.fullscreenChange}},[(_vm.predictions - _vm.fullscreen - _vm.mode)?_c('div',{staticClass:"fullscreen-wrapper__anchor"},[_c('b',[_vm._v("Label: ")]),_vm._v(" "+_vm._s(_vm.anchor.label)+" "),_c('b',[_vm._v("ID: ")]),_vm._v(" "+_vm._s(_vm.anchor.anchorId .toString(16) .padStart(12, "0") .toUpperCase() .match(/.{1,2}/g) .join(":"))+" "),_c('WifiIcon',{attrs:{"signal":_vm.anchor.rssi,"noConnection":_vm.anchor.lastHeartbeat
                ? _vm.isAnchorConnected(_vm.anchor.lastHeartbeat.createdDate)
                : false}}),(_vm.anchor.activeRouteStart)?[_vm._v(" | "),_c('b',[_vm._v(" Route Time: ")]),_c('span',{style:({
                color: _vm.computedColor,
                fontWeight: _vm.computedWeight
              })},[_c('span',[_vm._v(" "+_vm._s(_vm.getRouteTotalHours())+_vm._s(_vm.getRouteTotalMinutes())+"m:"+_vm._s(_vm.getRouteTotalSeconds())+"s ")]),(_vm.anchor.activeRouteOnTime)?_c('span',[_vm._v(" ("+_vm._s(_vm.getRouteDelay())+") ")]):_vm._e()])]:_vm._e()],2):_vm._e(),_c('div',{staticClass:"fullscreen-wrapper__details__clock"},[_c('i',{staticClass:"el-icon-timer"}),_vm._v(" "+_vm._s(_vm.moment.tz(this.clientData.timezone).format("HH:mm"))+" ")]),(_vm.predictions - _vm.fullscreen - _vm.mode)?_c('div',{staticClass:"fullscreen__close",on:{"click":function($event){return _vm.toggle()}}},[_c('i',{staticClass:"el-icon-close"})]):_vm._e(),_c('el-table',{ref:"predictions",class:`table__rows--${Math.min(
              Math.max(5, _vm.tableData.length),
              Math.max(_vm.numberOfRows, 5)
            )}`,staticStyle:{"width":"100%"},attrs:{"highlight-current-row":"","row-class-name":_vm.tableRowClassName,"data":_vm.tableData,"border":""}},[_c('el-table-column',{attrs:{"resizable":false,"label":"ID"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getTagInfo(scope.row.ord) .tagId.toString(16) .toUpperCase() .match(/.{1,2}/g) .join(""))+" ")]}}],null,false,90815019)}),_c('el-table-column',{attrs:{"resizable":false,"label":"Label"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.getTagInfo(scope.row.ord).label)+" ")]}}],null,false,167274567)}),_c('el-table-column',{attrs:{"resizable":false,"label":"Time to arrival"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(_vm.moment .tz(_vm.moment.unix(scope.row.prediction), _vm.clientData.timezone) .format("HH:mm"))+" ")]}}],null,false,3816651130)}),_c('el-table-column',{attrs:{"resizable":false,"label":"Status"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.state === 'LEFT')?_c('i',{staticClass:"el-icon-check"}):_vm._e(),(scope.row.state === 'CURRENTLY_HERE')?_c('i',{staticClass:"el-icon-check"}):_vm._e(),(scope.row.state === 'ON_THE_WAY')?_c('i',{staticClass:"el-icon-loading"}):_vm._e(),(scope.row.state === 'WAITING')?_c('i',{staticClass:"el-icon-timer"}):_vm._e(),_vm._v(" "+_vm._s(scope.row.state.charAt(0).toUpperCase() + scope.row.state .slice(1) .toLowerCase() .split("_") .join(" "))+" ")]}}],null,false,2676015931)})],1)],1):_vm._e()],1)]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }