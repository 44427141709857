var render = function render(){var _vm=this,_c=_vm._self._c;return _c('el-table',{staticClass:"full-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.anchorsData,"border":""},on:{"row-click":data => _vm.$emit('row-click', data)}},[_c('el-table-column',{attrs:{"resizable":false,"label":"ID (decimal)"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.anchorId)+" ")]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":"Label"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.label)+" ")]}}])}),(_vm.isClientDefault)?_c('el-table-column',{staticClass:"travel-rate",attrs:{"resizable":false,"label":"Travel Rate"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" Today: "+_vm._s(_vm.getTravelRate(scope.row.anchorId, "today"))),_c('br'),_vm._v(" Two Weeks: "+_vm._s(_vm.getTravelRate(scope.row.anchorId, "last-two-weeks"))+" ")]}}],null,false,1047287796)}):_vm._e(),_c('el-table-column',{attrs:{"resizable":false,"label":"MAC Address"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.anchorId .toString(16) .padStart(12, "0") .toUpperCase() .match(/.{1,2}/g) .join(":"))+" ")]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":"Signal","width":"70"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('WifiIcon',{attrs:{"signal":scope.row.rssi,"noConnection":scope.row.lastHeartbeat
            ? _vm.isAnchorConnected(
                scope.row.lastHeartbeat.powerSource,
                scope.row.lastHeartbeat.createdDate
              )
            : true}})]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":"BSSID"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.lastHeartbeat ? scope.row.lastHeartbeat.ssid : "")+" ")]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":"SSID NAME"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(_vm._s(scope.row.lastHeartbeat ? scope.row.lastHeartbeat.ssidName ? scope.row.lastHeartbeat.ssidName : "N/A" : "N/A")+" ")]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":"FW VERSION"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (_vm.firmwareVersions)?[_vm._v(_vm._s(_vm.getFirmwareVersion(scope.row.firmwareId))+" ")]:undefined}}],null,true)}),_c('el-table-column',{attrs:{"resizable":false,"label":"Power","width":"78"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{class:_vm.onOffClasses(
            'power-source',
            scope.row.lastHeartbeat ? scope.row.lastHeartbeat.powerSource : ''
          )},[_c('img',{attrs:{"src":require("@/assets/power_socket.svg"),"alt":"Power Source"}})])]}}])}),_c('el-table-column',{attrs:{"resizable":false,"label":"Last Seen","width":"150px"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return (scope.row.lastHeartbeat)?[_c('span',{style:({ color: _vm.computedColor(scope.row) })},[_vm._v(" "+_vm._s(_vm.moment .tz( _vm.moment.unix(scope.row.lastHeartbeat.createdDate), _vm.clientData.timezone ) .format("HH:mm, DD/MM/YYYY"))+" ")])]:undefined}}],null,true)}),(_vm.isAdmin)?_c('el-table-column',{attrs:{"resizable":false,"label":"Operations","width":"99"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('div',{staticClass:"edit-buttons"},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.handleEdit(scope.$index, scope.row)}}},[_c('i',{staticClass:"el-icon-edit"})])],1)]}}],null,false,3187114737)}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }