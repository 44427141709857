<template>
  <div class="routes-timeline">
    <span class="timeline-title">
      <span v-if="windowWidth > 1200">Recent Laps</span>
      <span v-else>Latest Lap</span>
      <el-tooltip placement="right" content="Lap analytics" :hide-after="2000">
        <el-button @click="redirectToLapAnalytics()">
          <img src="@/assets/bar-chart.svg" alt="bar-chart" />
        </el-button>
      </el-tooltip>
    </span>
    <div class="timeline-items">
      <div class="timeline-item " v-if="anchor.currentRouteInstance">
        <div class="timeline-content">
          <el-tooltip
            placement="top"
            content="Click here to analyze this lap"
            :hide-after="2000"
          >
            <div
              class="timeline-content__head"
              @click="
                redirectToSpecificLapAnalytics(anchor.currentRouteInstance.id)
              "
            >
              <div class="info-time">
                <span class="hour">{{
                  getStartTime(anchor.currentRouteInstance.startDate)
                }}</span>
              </div>
              <div class="info-label">
                <span class="active-marker"></span>
                <el-tooltip
                  placement="top"
                  :content="anchor.activeRoute.label"
                  :disabled="
                    !isTruncated(
                      anchor.currentRouteInstance.routeId,
                      anchor.currentRouteInstance.startDate,
                      true
                    )
                  "
                >
                  <span
                    class="label-text"
                    :ref="
                      `label-${anchor.currentRouteInstance.routeId}-${anchor.currentRouteInstance.startDate}`
                    "
                  >
                    {{ anchor.activeRoute.label }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </el-tooltip>
          <div class="timeline-content__details">
            <span>{{ currentTime }}</span>
            <span v-if="anchor.activeRouteOnTime"
              >({{ activeRouteDelay }})</span
            >
          </div>
        </div>
      </div>
      <div
        v-for="route in filteredRoutes"
        :key="route.id + route.startTime"
        :class="['timeline-item', route.error !== 0 ? 'error-layout' : '']"
      >
        <div class="timeline-content">
          <el-tooltip
            placement="top"
            content="Click here to analyze this lap"
            :hide-after="2000"
          >
            <div
              class="timeline-content__head"
              @click="redirectToSpecificLapAnalytics(route.id)"
            >
              <div class="info-time">
                <span class="hour">{{ getStartTime(route.startTime) }}</span>
                <span class="date" v-if="isAnotherDay(route.startTime)">
                  ({{ getStartTime(route.startTime, true) }})</span
                >
              </div>
              <div class="info-label">
                <img src="@/assets/check.svg" alt="check" />
                <el-tooltip
                  placement="top"
                  :content="route.routeLabel"
                  :disabled="!isTruncated(route.routeId, route.startTime)"
                >
                  <span
                    class="label-text"
                    :ref="`label-${route.routeId}-${route.startTime}`"
                  >
                    {{ route.routeLabel }}
                  </span>
                </el-tooltip>
              </div>
            </div>
          </el-tooltip>
          <div class="timeline-content__details">
            <span :class="{ 'red-text': route.error !== 0 }">{{
              getDuration(route.duration)
            }}</span>
            <span v-if="route.error === 0">{{
              getDelay(route.delay, route.error)
            }}</span>
            <span v-else>
              <RouteErrorTooltip :error="route.error" color="#FF0000" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatTimeUnit } from "@/utils/utils";
import { mapState, mapActions } from "vuex";
import moment from "moment-timezone";
import RouteErrorTooltip from "@/components/RouteErrorTooltip.vue";
import * as routerTypes from "@/router/router.types";
import * as actionTypes from "@/store/action-types";

export default {
  components: {
    RouteErrorTooltip
  },
  props: {
    anchor: {
      type: Object,
      required: true
    },
    currentTime: {
      type: String,
      default: ""
    }
  },
  data() {
    return {
      windowWidth: window.innerWidth
    };
  },
  computed: {
    ...mapState("clients", { clientData: "data" }),
    activeRouteStartDate() {
      return formatTimeUnit(
        this.anchor.currentRouteInstance.startDate,
        true,
        this.clientData
      );
    },
    activeRouteDelay() {
      let hours;
      let minutes;

      if (this.anchor.activeRouteOnTime[1] >= 0) {
        hours = Math.floor(this.anchor.activeRouteOnTime[1] / 3600);
        minutes = Math.floor((this.anchor.activeRouteOnTime[1] % 3600) / 60);
      } else {
        hours = Math.ceil(this.anchor.activeRouteOnTime[1] / 3600);
        minutes = Math.ceil((this.anchor.activeRouteOnTime[1] % 3600) / 60);
      }

      let minutesString = Math.abs(minutes).toString();

      if (minutes < 10 && minutes > -10)
        minutesString = "0".concat(minutesString);

      if (Math.abs(hours) >= 1)
        return (
          (this.anchor.activeRouteOnTime[1] < 0 ? "-" : "+") +
          (minutes > 0 ? hours.toString() : hours.toString()) +
          "h" +
          (minutes < 10 && minutes > -10
            ? "0" + minutesString
            : minutesString) +
          "m"
        );
      else
        return (
          (this.anchor.activeRouteOnTime[1] < 0 ? "-" : "+") +
          "00h" +
          minutesString +
          "m"
        );
    },
    filteredRoutes() {
      if (this.anchor.currentRouteInstance) {
        if (this.windowWidth > 1200 && this.anchor.recentRoutes.length === 3) {
          return this.anchor.recentRoutes.slice(0, -1);
        } else if (this.windowWidth > 1200) {
          return this.anchor.recentRoutes;
        } else {
          return [];
        }
      } else {
        if (this.windowWidth > 1200) {
          return this.anchor.recentRoutes;
        } else {
          return [this.anchor.recentRoutes[0]];
        }
      }
    }
  },
  methods: {
    ...mapActions("clients", {
      updateSelectedAnchor: actionTypes.CLIENTS_UPDATE_SELECTED_ANCHOR
    }),
    ...mapActions("user", {
      updateActiveRoute: actionTypes.USER_UPDATE_ACTIVE_ROUTE
    }),
    formatTimeUnit,
    getStartTime(startTime, isDateFormat = false) {
      return isDateFormat
        ? moment
            .tz(moment.unix(startTime), this.clientData.timezone)
            .format("DD/MM")
        : moment
            .tz(moment.unix(startTime), this.clientData.timezone)
            .format("HH:mm");
    },
    getDuration(duration) {
      return formatTimeUnit(duration, false, this.clientData);
    },
    getDelay(delay, error) {
      if (!error) {
        return delay > 0
          ? `(+${formatTimeUnit(delay, false, this.clientData)})`
          : `(-${formatTimeUnit(delay, false, this.clientData)})`;
      } else return "";
    },
    isAnotherDay(startTime) {
      const startDay = moment
        .tz(moment.unix(startTime), this.clientData.timezone)
        .dayOfYear();
      return moment().dayOfYear() !== startDay;
    },
    isTruncated(routeId, startTime, activeRoute) {
      const element = activeRoute
        ? this.$refs[`label-${routeId}-${startTime}`]
        : this.$refs[`label-${routeId}-${startTime}`]?.[0];
      return element && element.offsetWidth < element.scrollWidth;
    },
    redirectToLapAnalytics() {
      this.updateSelectedAnchor(this.anchor.anchorId);
      this.$router.push({
        name: routerTypes.ROUTE_STATS_LAPS,
        params: { routeId: this.anchor.recentRoutes[0].routeId }
      });
    },
    redirectToSpecificLapAnalytics(lapId) {
      this.updateSelectedAnchor(this.anchor.anchorId);
      this.$router.push({
        name: routerTypes.ROUTE_STATS_GLOBALS,
        params: { lapId: lapId }
      });
    },
    updateWindowWidth() {
      this.windowWidth = window.innerWidth;
    }
  },
  mounted() {
    window.addEventListener("resize", this.updateWindowWidth);
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.updateWindowWidth);
  }
};
</script>

<style lang="scss">
.routes-timeline {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  .timeline-title {
    display: flex;
    align-items: center;
    gap: 5px;
    text-align: left;
    font-size: 14px;
    font-weight: 600;
    color: #202426;

    .el-button {
      padding: 3px 5px;
      margin-left: 0;
    }
  }

  .timeline-items {
    display: flex;
    align-items: center;
    gap: 21px;

    .timeline-item {
      display: flex;
      width: 190px;
      flex-direction: column;
      gap: 6px;

      .timeline-content {
        display: flex;
        flex-direction: column;
        gap: 5px;

        &__head {
          display: flex;
          align-items: flex-start;
          gap: 6px;
          cursor: pointer;

          .info-time {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: #454947;
            font-weight: 700;
            height: 40px;

            .hour {
              font-size: 14px;
            }
            .date {
              font-size: 12px;
              font-weight: 600;
            }
          }

          .info-label {
            display: flex;
            width: 150px;
            height: 28px;
            padding: 5px 8px;
            align-items: center;
            gap: 12px;
            border-radius: 5px;
            border: 1px solid #eff0f0;
            background: #f1f1f1;

            .label-text {
              font-weight: 600;
              font-size: 14px;
              color: #202426;
              white-space: nowrap;
              text-overflow: ellipsis;
              overflow: hidden;
              max-width: 100px;
            }

            .active-marker {
              width: 10px;
              height: 10px;
              background-color: #3c8300;
              border-radius: 100%;
            }
          }
        }

        &__details {
          display: flex;
          justify-content: center;
          width: 150px;
          gap: 5px;
          font-weight: 700;
          color: #454947;
          font-size: 13px;
          align-self: flex-end;

          .red-text {
            color: #ff0000;
          }
        }
      }
    }
  }
}
</style>
