<template>
  <div class="user-tags">
    <div v-if="resources.loading" v-loading="true" class="user-tags__loading" />
    <div v-else-if="resources.error">
      Something went wrong. Contact the admin.
    </div>
    <template v-else>
      <div class="asset-area">
        <h3>TAGS</h3>
        <el-input
          slot="header"
          v-model="search"
          placeholder="Type to search"
          clearable
        ></el-input>
        <el-table
          @row-click="data => $emit('row-click', data)"
          :data="
            resources.data.tags.filter(
              tag =>
                tag.type == 1 &&
                (!search ||
                  tag.tagId
                    .toString(16)
                    .toLowerCase()
                    .includes(search.toLowerCase()))
            )
          "
          border
        >
          <el-table-column label="TAG ID">
            <template slot-scope="scope">
              <span>{{ scope.row.tagId.toString(16).toUpperCase() }}</span>
            </template>
          </el-table-column>
          <el-table-column label="LABEL">
            <template slot-scope="scope">
              {{ scope.row.label }}
            </template>
          </el-table-column>
          <el-table-column label="RANGE" width="78">
            <template slot-scope="scope">
              {{ -scope.row.threshold - 16 + " / 16" }}
            </template>
          </el-table-column>
          <el-table-column label="ARRIVAL TIME">
            <template slot-scope="scope">
              <span
                class="last-update"
                v-bind:style="{ color: computedColor(scope.row) }"
                style="text-align: left"
                v-if="scope.row.lastUpdate"
              >
                {{
                  moment
                    .tz(scope.row.lastUpdate * 1000, clientData.timezone)
                    .format("MMM DD YYYY, HH:mm")
                }}
              </span>
              <span v-else>
                <hr />
              </span>
            </template>
          </el-table-column>
          <el-table-column label="BATTERY" width="85">
            <template slot-scope="scope">
              <span v-if="scope.row.battery">
                <template v-if="scope.row.battery === 0">
                  <img src="@/assets/battery_dead.svg" alt="no battery" />
                </template>
                <template v-else-if="scope.row.battery === 3">
                  <img src="@/assets/battery-full.svg" alt="full battery" />
                </template>
                <template v-else-if="scope.row.battery === 2">
                  <img src="@/assets/battery-high.svg" alt="high battery" />
                </template>
                <template v-else-if="scope.row.battery === 1">
                  <img src="@/assets/battery-medium.svg" alt="medium battery" />
                </template>
                <template v-else>
                  <hr />
                </template>
              </span>
              <span v-else>
                <hr />
              </span>
            </template>
          </el-table-column>
          <el-table-column label="OPERATIONS" width="115">
            <template slot-scope="scope">
              <div class="edit-buttons">
                <el-button
                  size="mini"
                  @click="handleAssetEdit(scope.$index, scope.row)"
                  ><i class="el-icon-edit"></i
                ></el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </template>

    <div v-if="resources.data.tags.length" class="refresh">
      <el-button type="primary" @click="updateBeacons()"
        >Refresh Status</el-button
      >
    </div>

    <el-dialog title="Edit Tag" :visible.sync="editAssetDialog.visible">
      <div class="edit-beacon-values">
        <span>
          <b>ID</b>
        </span>
        <span>
          <b>CURRENT BATTERY</b>
        </span>
        <span>
          <b>ARRIVAL TIME</b>
        </span>
      </div>
      <div class="edit-beacon-values">
        <span>
          {{
            tagForm.tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join("")
          }}
        </span>
        <span>
          <TempBatteryIcon :battery="tagForm.battery" />
        </span>
        <span>
          {{ moment.utc(tagForm.lastSeen * 1000).format("MMM DD YYYY, HH:mm") }}
        </span>
      </div>
      <el-form
        label-position="right"
        :model="tagForm"
        :rules="rules"
        ref="tagForm"
        hide-required-asterisk
        @submit.native.prevent
      >
        <div class="edit-beacon-form">
          <div>
            <el-form-item label="Set Tag Label" prop="label">
              <el-input
                type="text"
                v-model="tagForm.label"
                autocomplete="off"
                @keyup.enter.native="saveTag(false)"
              />
            </el-form-item>
            <el-form-item label="Set Tag Range" class="form-item-slider">
              <el-slider
                v-model="tagForm.threshold"
                :marks="marksNonDefault"
                :step="1"
                :min="0"
                :max="16"
              >
              </el-slider>
            </el-form-item>
            <el-form-item label="Replace Tag" v-if="isClientDefault">
              <el-select
                v-model="tagToReplace"
                placeholder="Select tag"
                filterable
                width="100%"
              >
                <el-option
                  v-for="tag in inactiveTags"
                  :key="`tag.label${tag.tagId}`"
                  :label="
                    `(${tag.tagId
                      .toString(16)
                      .toUpperCase()
                      .match(/.{1,2}/g)
                      .join('')}) ${tag.label}`
                  "
                  :value="tag.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="Active" v-if="isClientDefault">
              <el-checkbox v-model="tagForm.active">
                {{ tagForm.active ? "Active" : "Inactive" }}
              </el-checkbox>
            </el-form-item>
          </div>
          <el-form-item label="Associated Beacon" v-if="isClientDefault">
            <el-select v-model="tagForm.beaconId" class="select-beacon">
              <el-option
                v-for="item in resources.data.tags.filter(
                  tag => tag.type === 0
                )"
                :key="item.id"
                :label="`${item.label} (${toHex(item.tagId)})`"
                :value="item.tagId"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
        <div
          v-if="tagForm.error.length"
          class="el-form-item__error el-form-item__error--server"
        >
          {{ tagForm.error }}
        </div>
      </el-form>
      <span slot="footer" class="dialog-footer" style="marginTop: -10%">
        <el-button
          @click="editAssetDialog.visible = false"
          :disabled="editAssetDialog.loading"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave"
          :loading="editAssetDialog.loading"
          >Save</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :title="isClientDefault ? 'Edit Location' : 'Edit Tag'"
      :visible.sync="editTagDialog.visible"
    >
      <div class="edit-beacon-values">
        <span>
          <b>ID</b>
        </span>
        <span>
          <b>CURRENT BATTERY</b>
        </span>
        <span>
          <b>ARRIVAL TIME</b>
        </span>
      </div>
      <div class="edit-beacon-values">
        <span>
          {{
            tagForm.tagId
              .toString(16)
              .toUpperCase()
              .match(/.{1,2}/g)
              .join("")
          }}
        </span>
        <span>
          <template v-if="tagForm.battery === 0">
            <img src="@/assets/battery_dead.svg" alt="no battery" />
          </template>
          <template
            v-else-if="
              tagForm.battery == 1 ||
                tagForm.battery == 2 ||
                tagForm.battery == 3
            "
          >
            <component :is="batteryIcon" :battery="tagForm.battery" />
          </template>
          <template v-else>
            <hr />
          </template>
        </span>
        <span>
          {{ moment.utc(tagForm.lastSeen * 1000).format("MMM DD YYYY, HH:mm") }}
        </span>
      </div>
      <!-- <el-form
        label-position="right"
        :model="tagForm"
        :rules="rules"
        ref="tagForm"
        hide-required-asterisk
        @submit.native.prevent
      >
        <div class="edit-beacon-form">
          <div>
            <el-form-item
              :label="isClientDefault ? 'Set Location Label' : 'Set Tag Label'"
              prop="label"
              class="edit-beacon-label"
            >
              <el-input
                type="text"
                v-model="tagForm.label"
                autocomplete="off"
                @keyup.enter.native="saveTag(false)"
              />
            </el-form-item>
            <el-form-item
              label="Set Location State"
              class="edit-beacon-status"
              v-if="isClientDefault"
            >
              <el-checkbox
                v-model="tagForm.active"
                size="small"
                @change="onTagActiveStatusChange"
                >Installed</el-checkbox
              >
            </el-form-item>
            <el-form-item
              :label="isClientDefault ? 'Set Location Range' : 'Set Tag Range'"
              class="edit-beacon-range-level"
            >
              <span v-if="isClientDefault">
                <el-slider
                  v-model="tagForm.threshold"
                  :marks="marks"
                  :step="1"
                  :min="1"
                  :max="8"
                >
                </el-slider>
              </span>
              <span v-else>
                <el-slider
                  v-model="tagForm.threshold"
                  :marks="marksNonDefault"
                  :step="1"
                  :min="0"
                  :max="16"
                >
                </el-slider>
              </span>
            </el-form-item>
            <el-form-item
              label="Replace Location"
              class="edit-beacon-replace"
              v-if="isClientDefault"
            >
              <el-select
                v-model="tagToReplace"
                placeholder="Select Location"
                filterable
                class="select-tags"
              >
                <el-option
                  v-for="tag in inactiveTags"
                  :key="`tag.label${tag.tagId}`"
                  :label="
                    `(${tag.tagId
                      .toString(16)
                      .toUpperCase()
                      .match(/.{1,2}/g)
                      .join('')}) ${tag.label}`
                  "
                  :value="tag.id"
                ></el-option>
              </el-select>
            </el-form-item>
          </div>
          <div>
            <el-form-item
              label="Child Beacons"
              class="edit-beacon-children"
              v-if="isClientDefault"
            >
              <div class="edit-beacon-children">
                <div>
                  <el-tooltip
                    class="item"
                    effect="dark"
                    content="Child beacons share log information with their parents. Beacons that are already child beacons cannot have children!"
                    placement="right"
                  >
                    <el-select
                      v-model="childTagToAdd"
                      placeholder="Select Beacon"
                      filterable
                      class="edit-beacon-select-tags"
                    >
                      <el-option
                        v-for="tag in orphanTags"
                        :key="`tag.label${tag.tagId}`"
                        :label="
                          `(${tag.tagId
                            .toString(16)
                            .toUpperCase()
                            .match(/.{1,2}/g)
                            .join('')}) ${tag.label}`
                        "
                        :value="tag.tagId"
                      ></el-option>
                    </el-select>
                  </el-tooltip>
                </div>
                <div class="add-tag-btn">
                  <el-button
                    type="primary"
                    size="small"
                    icon="el-icon-plus"
                    circle
                    @click="addTagToGroup"
                  />
                </div>
              </div>
            </el-form-item>
            <div>
              <div
                class="edit-beacon-child-tags"
                v-for="tag in tagsSelected"
                :key="tag.id"
              >
                <div />
                <div class="anchor-list">
                  <div class="background-animation">
                    <span class="anchor-list__id">
                      {{
                        `${tag.id
                          .toString(16)
                          .toUpperCase()
                          .match(/.{1,2}/g)
                          .join("")} (${tag.label})`
                      }}
                    </span>
                  </div>
                  <span class="anchor-list__delete">
                    <el-button
                      size="small"
                      type="danger"
                      class="el-icon-delete"
                      circle
                      @click="removeTagFromSelected(tag)"
                    />
                  </span>
                </div>
              </div>
              <div v-if="tagToEdit.childTags">
                <div
                  class="edit-beacon-child-tags"
                  v-for="tag in tagToEdit.childTags"
                  :key="tag.id"
                >
                  <div></div>
                  <div class="anchor-list">
                    <div class="label-div">
                      <span class="anchor-list__id">
                        {{
                          `${tag.id
                            .toString(16)
                            .toUpperCase()
                            .match(/.{1,2}/g)
                            .join("")} (${tag.label})`
                        }}
                      </span>
                    </div>
                    <span class="anchor-list__delete">
                      <el-button
                        size="small"
                        type="danger"
                        class="el-icon-delete"
                        circle
                        @click="removeTag(tag)"
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="tagForm.error.length"
          class="el-form-item__error el-form-item__error--server"
        >
          {{ tagForm.error }}
        </div>
      </el-form> -->
      <span slot="footer" class="dialog-footer" style="marginTop: -10%">
        <el-button
          @click="editTagDialog.visible = false"
          :disabled="editTagDialog.loading"
          >Cancel</el-button
        >
        <el-button
          type="primary"
          @click="confirmSave"
          :loading="editTagDialog.loading"
          >Save</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="Save Changes"
      :visible.sync="certifyEditTagDialogSave"
      width="500px"
    >
      <span class="warning"
        >Are you sure you want to save the changes that you made? <br />
      </span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="certifyEditTagDialogSave = false" size="small"
          >Continue Editing</el-button
        >
        <el-button type="primary" @click="finalSaveTag" size="small"
          >Save Changes</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      title="Attention"
      :visible.sync="showAssociatedRoutes"
      width="400px"
    >
      <div class="dialog-info">
        The edit beacon belongs to the Route(s) shown below.
      </div>
      <div v-for="route in routesAssociatedToTag" :key="route.id">
        <span>{{ route.label }}</span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";
import tagsApi from "@/modules/tags/api/tags.api";
import moment from "moment-timezone";
import positionLogsApi from "@/modules/position-logs/api/position-logs.api";
import BatteryIcon from "@/components/icons/BatteryIcon.vue";
import TempBatteryIcon from "@/components/icons/TempBatteryIcon.vue";
import RoutesApi from "@/modules/routes/api/routes.api";

export default {
  components: { BatteryIcon, TempBatteryIcon },
  data() {
    return {
      certifyEditTagDialogSave: false,
      childTagToAdd: null,
      editAssetDialog: {
        visible: false,
        loading: false
      },
      editTagDialog: {
        visible: false,
        loading: false
      },
      finalTags: [],
      inactiveTags: [],
      marks: {
        1: "Min",
        8: "Max"
      },
      marksNonDefault: {
        0: "Min",
        16: "Max"
      },
      moment,
      orphanTags: [],
      routeList: [],
      routesAssociatedToTag: [],
      rules: {
        label: [
          {
            max: 45,
            message: "Label can have 45 characters at the most",
            trigger: "blur"
          }
        ]
      },
      showAssociatedRoutes: false,
      tagForm: {
        tagId: 0,
        label: "",
        active: false,
        threshold: null,
        battery: -1,
        lastSeen: -1,
        error: "",
        beaconId: null
      },
      tagsSelected: [],
      tagsToDelete: [],
      tagsToSend: [],
      tagToEdit: "",
      tagToReplace: null,
      tempOrphanTags: [],
      search: ""
    };
  },
  props: {
    showOperations: {
      type: Boolean,
      default: true
    },
    showActiveRoute: {
      type: Boolean,
      default: false
    },
    batteryIcon: {
      type: Object,
      required: true
    }
  },

  computed: {
    ...mapState("user", {
      resources: "resources"
    }),
    ...mapState("clients", {
      clientData: "data",
      clientAnchors: "anchors"
    }),
    ...mapGetters("auth", ["isAdmin", "isTracker"]),
    ...mapGetters("clients", ["isClientDefault"])
  },

  methods: {
    computedColor(tag) {
      if (this.isTagOutdated(tag)) return "red";
      else return "";
    },
    isTagOutdated(tag) {
      const oneWeekAgo = Math.ceil(
        moment()
          .tz(this.clientData.timezone)
          .subtract(7, "days")
          .valueOf() / 1000
      );
      if (tag.lastUpdate < oneWeekAgo) return true;
      return false;
    },
    async updateBeacons() {
      try {
        const { data } = await tagsApi.getRefreshStatus();
        this.resources.data.tags = data;
        this.orderTags();
      } catch (_) {
        // DO nothing
      }
    },

    thresholdToRange(threshold) {
      return threshold >= -16 ? 0 : -threshold - 16;
    },

    finalSaveTag() {
      this.saveTag(false);
      this.certifyEditTagDialogSave = false;
    },

    addTagToGroup() {
      const tagSelected = this.childTagToAdd;
      const index = this.resources.data.tags.findIndex(
        elem => elem.tagId === tagSelected
      );
      this.tagsSelected.push({
        id: tagSelected,
        label: this.resources.data.tags[index].label
      });
      this.tagsToSend.push(tagSelected);
      this.childTagToAdd = null;
      this.orphanTags = this.orphanTags.filter(
        elem => elem.tagId !== tagSelected
      );
    },

    handleAssetEdit(index, row) {
      this.editAssetDialog.visible = true;
      this.tagToEdit = row;
      this.tagForm.label = row.label;
      this.tagForm.id = row.id;
      this.tagForm.active = row.active;
      this.tagForm.threshold = this.thresholdToRange(row.threshold);
      this.tagForm.battery = row.battery;
      this.tagForm.lastSeen = row.lastUpdate;
      this.tagForm.tagId = row.tagId;
      this.tagForm.beaconId = row.beaconId;
    },

    handleEdit(index, row) {
      this.editTagDialog.visible = true;
      this.orphanTags = this.tempOrphanTags.filter(t => t.id !== row.id);
      this.inactiveTags = this.resources.data.tags.filter(
        tag => tag.active === false
      );
      this.inactiveTags = [
        {
          tagId: 0,
          id: 0,
          label: "Reset Selection"
        }
      ].concat(this.inactiveTags);

      this.tagToEdit = row;
      this.tagForm.label = row.label;
      this.tagForm.id = row.id;
      this.tagForm.active = row.active;
      this.tagForm.threshold = this.thresholdToRange(row.threshold);
      this.tagForm.battery = row.battery;
      this.tagForm.lastSeen = row.lastUpdate;
      this.tagForm.tagId = row.tagId;
      this.routesAssociatedToTag = this.routeList.filter(route => {
        return route.tags.some(tag => tag.tagId == this.tagForm.tagId);
      });
    },
    sortByLastUpdate(arr) {
      arr.sort((a, b) => {
        if (a.lastUpdate < b.lastUpdate) return 1;
        else return -1;
      });
    },

    removeTagFromSelected(tag) {
      this.tagsSelected = this.tagsSelected.filter(elem => elem.id !== tag.id);
      this.tagsToSend = this.tagsToSend.filter(elem => elem !== tag.id);
      this.orphanTags.push(
        this.resources.data.tags[
          this.resources.data.tags.findIndex(elem => elem.tagId === tag.id)
        ]
      );
      this.$forceUpdate();
    },

    removeTag(tag) {
      this.tagToEdit.childTags = this.tagToEdit.childTags.filter(
        elem => elem.id !== tag.id
      );
      this.tagsToDelete.push(tag.id);
      this.orphanTags.push(
        this.resources.data.tags[
          this.resources.data.tags.findIndex(elem => elem.tagId === tag.id)
        ]
      );
      this.$forceUpdate();
    },

    orderTags() {
      const noBatteryTags = this.resources.data.tags.filter(
        tag => tag.battery === 0 && tag.active
      );
      this.sortByLastUpdate(noBatteryTags);

      const tagsNotSeen = this.resources.data.tags.filter(
        tag => this.isTagOutdated(tag) && tag.battery != 0 && tag.active
      );
      this.sortByLastUpdate(tagsNotSeen);

      const badTags = [].concat(noBatteryTags).concat(tagsNotSeen);

      const restOfTags = this.resources.data.tags.filter(
        tag => tag.battery != 0 && badTags.indexOf(tag) === -1 && tag.active
      );
      this.sortByLastUpdate(restOfTags);

      const notActiveTags = this.resources.data.tags.filter(tag => !tag.active);
      this.sortByLastUpdate(notActiveTags);

      this.resources.data.tags = badTags
        .concat(restOfTags)
        .concat(notActiveTags);
    },

    confirmSave() {
      if (this.tagsSelected.length != 0 || this.tagToReplace != null)
        this.certifyEditTagDialogSave = true;
      else this.saveTag(false);
    },

    discardChanges() {
      this.tagsSelected = [];
      this.tagToReplace = null;
      this.certifyEditTagDialogDiscard = false;
      this.editTagDialog.visible = false;
    },

    async replaceTag() {
      this.replaceTagDialog.loading = true;
      try {
        const tag = this.resources.data.tags.find(
          tag => tag.id === this.tagToReplace
        );
        const oldTag = this.resources.data.tags.find(
          tag => tag.id === this.tagToEdit.tagId
        );

        const lastPosition = oldTag.lastPosition;
        await positionLogsApi.updateTagPosition(this.tagToEdit.id, {
          tagId: tag.id,
          x: lastPosition.x,
          y: lastPosition.y
        });

        this.$notify({
          title: "Success",
          message: `Beacon Replaced`,
          type: "success"
        });
      } catch (err) {
        this.$notify.error({
          title: "Error",
          message: "Something went wrong. Try again later."
        });
      } finally {
        this.replaceTagDialog.loading = false;
        this.replaceTagDialog.visible = false;
      }
    },

    async saveTag(destroy) {
      if (!destroy)
        this.$refs.tagForm.validate(async valid => {
          if (valid) {
            this.editTagDialog.loading = true;
            let threshold = 0;

            if (this.isClientDefault) {
              threshold = -this.tagForm.threshold - 16;
            } else {
              if (this.tagForm.threshold == 0) threshold = -16;
              else threshold = -32 + (16 - this.tagForm.threshold);
            }

            try {
              const tag = this.resources.data.tags.find(
                tag => tag.tagId === this.tagToEdit.tagId
              );
              const foundTag = this.resources.data.tags.find(
                tag => tag.tagId == this.tagForm.beaconId
              );
              if (this.tagToReplace != null) this.replaceTag();
              const result = await tagsApi.updateTag(this.tagToEdit.tagId, {
                label: this.tagForm.label,
                active: this.tagForm.active,
                threshold: threshold,
                childBeacons: this.tagsToSend,
                beaconsToRemove: this.tagsToDelete,
                beaconId: foundTag ? foundTag.id : null
              });

                const _self = this; // eslint-disable-line
              this.resources.data.tags.forEach(elem => {
                if (elem.id === _self.tagToEdit.id) {
                  elem.label = result.data.label;
                  elem.active = result.data.active;
                  elem.threshold = result.data.threshold;
                  elem.battery = result.data.battery;
                  elem.lastUpdate = result.data.lastUpdate;
                  elem.childTags = _self.tagToEdit.childTags;
                  elem.beaconId = result.data.beaconId;
                }
              });

              this.tagForm = {
                tagId: 0,
                label: "",
                active: false,
                threshold: null,
                battery: -1,
                lastSeen: -1,
                error: "",
                beaconId: null
              };
              this.tagsToSend = [];
              this.tagsSelected = [];
              this.tagsToDelete = [];
              this.editTagDialog.visible = false;
              this.editAssetDialog.visible = false;
              this.$forceUpdate();
            } catch (err) {
              this.$notify.error({
                title: "Error",
                message: "Something went wrong. Try again later."
              });
            } finally {
              this.editTagDialog.loading = false;
            }
          }
        });
      else {
        try {
          const tag = this.resources.data.tags.find(
            tag => tag.tagId === this.resourceInfo.details.tagId
          );
          const res = await tagsApi.updateTag(this.resourceInfo.details.tagId, {
            destroyed: true
          });
          tag.destroyed = res.data.destroyed;
          this.removeResourceFromMap([tag], "tags");
          this.$notify({
            title: "Success",
            message: "Beacon deleted",
            type: "success"
          });
        } catch (err) {
          this.$notify.error({
            title: "Error",
            message: "Something went wrong. Try again later."
          });
        } finally {
          console.log("deleted");
        }
      }
    },

    async fetchRouteList() {
      try {
        const res = await RoutesApi.getRouteList();
        this.routeList = res.data;
        this.checkTagsThatAreInRoute();
      } catch (error) {
        console.log(error);
      }
    },

    onTagActiveStatusChange() {
      if (!this.tagForm.active && this.routesAssociatedToTag.length > 0) {
        this.showAssociatedRoutes = true;
      }
    },

    checkTagsThatAreInRoute() {
      this.resources.data.tags.forEach(tag => {
        tag.inRoute = this.routeList.some(route =>
          route.tags.some(routeTag => routeTag.id === tag.tagId)
        );
      });
    },
    toHex(str) {
      return str
        .toString(16)
        .toUpperCase()
        .match(/.{1,2}/g)
        .join("");
    }
  },

  watch: {
    "editTagDialog.visible": {
      handler(newValue) {
        if (!newValue) {
          if (this.tagsSelected.length != 0 || this.tagToReplace != null) {
            this.certifyEditTagDialogDiscard = true;
            this.editTagDialog.visible = true;
          } else {
            this.discardChanges();
          }
        }
      }
    },

    tagToReplace(newValue) {
      if (newValue === 0) this.tagToReplace = null;
    }
  },

  async created() {
    this.isLoading = true;
    this.orderTags();

    let totalTags = [];
    if (this.clientAnchors.data)
      this.clientAnchors.data.forEach(elem => {
        if (elem.activeRoute)
          if (elem.activeRoute.tags)
            totalTags = totalTags.concat(elem.activeRoute.tags);
      });
    else
      this.clientAnchors.forEach(elem => {
        if (elem.activeRoute)
          if (elem.activeRoute.tags)
            totalTags = totalTags.concat(elem.activeRoute.tags);
      });

    this.tempOrphanTags = this.resources.data.tags.filter(
      tag =>
        tag.numberOfChildren === 0 &&
        tag.parentId == null &&
        tag.active &&
        !totalTags.some(t => t.tagPosition.tagId === tag.id)
    );

    this.resources.data.tags.forEach(elem => {
      elem.childTags = [];
    });

      const _self = this; // eslint-disable-line
    this.resources.data.tags.forEach(element => {
      const index = _self.resources.data.tags.findIndex(
        elem => elem.id == element.parentId
      );
      if (index !== -1) {
        _self.resources.data.tags[index].childTags.push({
          id: element.tagId,
          label: element.label,
          parentId: element.parentId
        });
      }
    });

    // this.finalTags = this.resources.data.tags.filter(tag => tag.battery === 0);
    // const oneWeekAgo = Math.ceil(
    //   moment()
    //     .utc()
    //     .subtract(7, "days")
    //     .valueOf() / 1000
    // );
    // this.offlineTags = this.resources.data.tags.filter(
    //   tag => tag.active && tag.lastUpdate < oneWeekAgo
    // );
    this.fetchRouteList();
    this.isLoading = false;
  }
};
</script>

<style lang="scss">
.user-tags {
  padding: 24px 0;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-dialog {
    width: 920px;

    @media (max-width: 860px) {
      width: 400px;
      display: flex;
      flex-direction: column;
    }
  }

  .dialog-info {
    margin-bottom: 10px;
  }

  .location-area {
    display: flex;
    flex-direction: column;
    padding: 10px;
    width: 1024px;

    @media (max-width: 1400px) {
      width: 100%;
    }

    h3 {
      margin-bottom: 1.5rem;
    }
  }

  .asset-area {
    width: 1024px;

    @media (max-width: 1400px) {
      width: 100%;
    }

    h3 {
      margin-bottom: 1.5rem;
    }
  }

  .range-level {
    margin-top: 8%;
  }

  .checkbox-active {
    margin-top: 6%;
  }

  .threshold-slider {
    margin-top: 8%;
  }

  .min-max {
    display: block;

    .min {
      float: left;
    }

    .max {
      float: right;
    }
  }

  #hidden-battery {
    position: absolute;
    top: -500px;
    z-index: -1;
  }

  .background-animation {
    -webkit-animation-name: animation;
    -webkit-animation-duration: 4s;
    -webkit-animation-timing-function: ease-in-out;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-play-state: running;

    animation-name: animation;
    animation-duration: 4s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
    animation-play-state: running;
    background-color: white;
    width: 83%;
  }

  .anchor-list {
    display: flex;
    position: relative;

    .label-div {
      width: 83%;
    }

    &__id {
      float: left;
      margin-bottom: 3%;
      padding-top: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100%;
      display: flex;
      justify-content: left;
    }

    &__delete {
      float: right;
      position: absolute;
      right: 10px;
    }
  }

  .edit-beacon-values {
    display: grid;
    grid-template-columns: 33% 34% 33%;
    margin-bottom: 2%;
  }

  .edit-beacon-form {
    display: grid;
    grid-template-columns: 50% 50%;
    border-top: 1px solid black;
    padding-top: 3%;
    margin-top: 20px;

    @media (max-width: 860px) {
      display: flex;
      flex-direction: column;
    }

    .el-form-item {
      margin-bottom: 15px;
      display: grid;
      align-items: center;
      grid-template-columns: 1% 35% 45%;
      justify-items: flex-start;
      height: min-content;

      &__content {
        width: 100%;
        text-align: left;
      }
    }

    .edit-beacon-range-level {
      .el-form-item__label {
        padding-top: 10px;
      }
      .el-form-item__content {
        margin-left: 0px;
        width: 95%;
      }
    }

    .edit-beacon-replace {
      margin-top: 13%;
      display: flex;
      align-items: center;

      .select-tags {
        width: 95%;
        margin-left: 32px;
      }
    }
    .edit-associated-beacon {
      display: flex;
      align-items: center;
      height: min-content;

      .select-beacon {
        width: 95%;
        margin-left: 32px;
      }
    }

    .edit-beacon-active {
      margin-top: 13%;
      display: flex;
      align-items: center;

      .el-checkbox {
        width: 95%;
        margin-left: 32px;
      }
    }

    .edit-beacon-select-tags {
      padding-left: 14px;
      width: 115%;

      @media (max-width: 860px) {
        width: 100%;
      }
    }

    .add-tag-btn {
      padding-left: 67px;

      @media (max-width: 860px) {
        padding-left: 22px;
      }
    }

    .edit-beacon-child-tags {
      display: grid;
      grid-template-columns: 32% 68%;
      margin-bottom: 3%;
    }

    .edit-beacon-children {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .edit-beacon-label {
      display: flex;
      justify-content: left;
      align-items: center;

      .el-input {
        margin-left: 12%;
      }
    }

    .edit-beacon-status {
      display: flex;
      justify-content: left;
      align-items: center;

      .el-checkbox {
        margin-left: 45%;
      }
    }

    .form-item-slider {
      padding-bottom: 20px;
    }

    .el-slider {
      width: 100%;

      &__marks-text {
        width: 100px;
        color: black;
        text-align: center;
      }
    }
  }

  .background-red {
    background-color: "red";
    height: 100%;
    width: 100%;
    position: absolute;
    right: 0;
    top: 0;
  }

  .el-table {
    overflow-x: auto;
  }

  .low-battery {
    margin-bottom: 1%;
  }

  .tags-not-seen {
    margin-top: 3%;
    margin-bottom: 1%;
  }

  .refresh {
    margin-top: 2%;
  }

  // .el-table.expandable-table {
  //   display: none;

  //   @media (max-width: 1080px) {
  //     display: block;
  //   }

  //   .el-table__expanded-cell {
  //     padding: 20px 40px 24px;
  //   }

  //   .expandable-table__signal {
  //     .wifi {
  //       top: 24px;
  //       display: inline-block;
  //     }

  //     .wifi__no-connection {
  //       top: 26px;
  //       left: 0px;
  //     }
  //   }

  //   .expandable-table__id,
  //   .expandable-table__ssid {
  //     margin-top: 24px;
  //   }

  //   .user-tags__power-source {
  //     text-align: left;

  //     &::before {
  //       left: 72px;
  //       bottom: -18px;
  //       z-index: 1;
  //     }

  //     img {
  //       position: relative;
  //       top: 16px;
  //     }
  //   }

  //   .expandable-table__last-seen {
  //     margin-top: 26px;
  //   }

  //   .expandable-table__online-since {
  //     margin-top: 12px;
  //   }
  // }

  .cell span {
    display: block;
    text-align: center;

    i {
      font-size: 24px;
    }
  }

  .cell .edit-buttons {
    display: flex;
    justify-content: center;
    i {
      font-size: 15px;
    }
  }

  &__power-source--on {
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAACy0lEQVRIS62VT0hUURTGv3NfERqtJPpDkKkE6ZgzU9Amg2rTSpzpD6YWtIiiTMfEsNoWkYTOTC1q0SIq1LSZqaBFhQsXUkSNWUZQYSU1gumi1Epn7on3hhnfPN/om+zu3jvnfL/vnXvufYQ51qagP5+ZK2LAdgguAnNOPF18B+MtCe6WQmnrLzsxmE6GzAKbu1rypklpFpBuCGGak6yTkklRuiTHTvW56j8Z9WYVO0Leg5LpqgCy5vo6Y0xKOakIOvLS5bmlj6UAHAFvE4guZCJszGVCY1953aXE+yRAdQ6mGwsRT9QyU3Wfu/a2+qwBtJ4ryptM25LWjMQEoBSFd9d81gCOgK8ThD3/w/2MBnWEXbUVpI6ilLH3805LGrrq0JVrw8Ohd/gdi85kSckskEfOgO8sE879i3tVvKF4Gyrz7Xg2MoT6pw9SIcynqSTgeyIIOzMF6MXV2onoFA71dOLDj1G91GMqCXkjgmmlEZCzJBujfyZNuWbix3pDeD02nJrP+EolwdYpAbFYH6nKd+Dohi2o6b2HV2ORlCLL4vGqqVkAR85qXC+ND9RkdBrHe0NJiCp+srgUqoFEW0ydz1hSAb5vAlilP3ke21YcKHCmQPrHIqi3laK6wLI4oLXIZJNVp0ZIz/Agdq1Zb9W5lkeQj8ge8p4hpvPG3TRCEnF1WuZpi+6sURNtvH95nTId/Wh20IyQjMQBCankaVeFM+S/w8x7zWYyAXHn2qw714S4LezyVGoAe7A1lyUGhBDZ6SArspZh+NdPi+dRji8iWfi8vGEoeV07g95qBt20qDB3GmN/2F3XHt9o3bIH/I1E3LwgCKMh7K5r0Y99ip494K8i5msQWJoZSI6DxeGE87QANeC4e2UthLwIGdtn4RqXAHcIBU0vyjxfzMY9rVF184mpAkQ7wCgEYXl8QDBCJAeYlG7ERLv650on8hf6Czzo4QTILgAAAABJRU5ErkJggg==");
    }
  }

  &__power-source--off {
    position: relative;
    text-align: center;

    &::before {
      content: "";
      position: absolute;
      right: 0;
      bottom: 0;
      width: 24px;
      height: 24px;
      background-color: white;
      border-radius: 50%;
      background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAC9UlEQVRIS62Wy08TURTGv3unmKjLtgkaE23ZwRJ6qbhSN7SUR4waVOICIv4BBlNeLRSN0RjXsoDEqBHUNU/DTmCmshM3hvpAYSF1hcagc6+5E2YyHWcGEWfXOWe+37nndUvg87yqrq7QFaWVAycBVAEISncBbBDgDQXmADyp1bR3XjLEzZCvqYkKQu7ohJyhgKuP+R0HBAWec0qvH19cfO/U++NjlbHL4Pw+KN3vdzqnjQPfFSGusnz+kd1WAtBisbQg5NZuhJ2+gpCuuKreNd9bACNy4MFexC1RIdpYPv9Y/jYAMucceG1Py+GODmytr2NjYsKXGW5pQVkwiLWREcuPA994IFB1Yn7+gwFQGXsG4KzpIcWPdHYCnKMwNOQJCTc3I9LdDRCCT8PDWBsdtSCCkPG4qrYS2Yo/FeWtvVtCySSi/f0ApZ6QcGMjIr29hrjgHCuZDL7OztpPIUBplKiM9QK44cxDqKEB0b4+V0g4lUJE2jzEbbXoJguMvaDAabdEh1IpRGWUtpNIURMsIy9kMijaIi9pUSFmJWCdAuVelTSitUEMP0qNtBSyWRRnZrybQNc/S8AWBcr8WsWeb2NVSPGBARSnp307jANb/w4YHERxampnwDxjawpwyDNFTU2I9PRYBTWGx0zRTpDtFHkW2d7nZlpkkSuyWavwK7kcipOT7vFxPiPbtAfATaeHnNBIOm1Fbi9osL6+BFLI5bDhDklLQIQDK/ZB8xM3AwkmEqjIZDyHkcsRDQSi5qp4CuCcc1Xs1OehRALRbYhzVWxfRBcNwEI8fgycL1PggAVpb8eP1dWS8XcdxmQS+8rLS/YQgE0iRCXL51etda3FYm2CkIf/Y10LQi7EVXXMWtemqMpYF4A7e4Rcq9W0e9Y+coppsdglnZBhChzcJWhTEHLFjNwTIA0v6+qOKrp+Wwhx/i8ufU6BcUXX0zVLSx+dQfn+Y5DFV3S9FcApwXklV5Swset0/QsIWQalc78CgTF5c3md9jeDjGiF9sPCPQAAAABJRU5ErkJggg==");
    }
  }
}
</style>
