var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tag-list"},[_c('div',{staticClass:"header-content"},[_c('h3',[_vm._v("Tags Data")]),_c('div',{staticClass:"header-content__details"},[_c('span',[_vm._v("Quantity: "+_vm._s(_vm.assetList?.length))]),_c('div',{staticClass:"header-content-display-flex__inputs"},[_c('div',{staticClass:"autocomplete-search"},[_c('el-autocomplete',{staticClass:"autocomplete-search__input-bar",attrs:{"fetch-suggestions":_vm.fetchSuggestions,"placeholder":"Search by Tag or Location","clearable":""},on:{"select":_vm.handleSelectAsset,"clear":_vm.clearSearchInfo,"input":_vm.clearSearchInfo},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('div',{staticClass:"no-match-message"},[_vm._v("No match found")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)])])]),_c('div',{attrs:{"clas":"table-container"}},[_c('el-table',{staticClass:"asset-list-table",staticStyle:{"width":"100%"},attrs:{"data":_vm.searchedInfo.length != 0 ? _vm.searchedInfo : _vm.assetList,"row-class-name":_vm.tableRowClassName,"max-height":_vm.windowInnerHeight - 250}},[_c('el-table-column',{attrs:{"width":"25px","align":"start"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              _vm.resources.sidebarAnchor?.currentTagId === scope.row.tagId &&
                _vm.hasBeenLessThanXSeconds(scope.row.lastTs, 10)
            )?_c('el-tooltip',{key:scope.row.beaconId + '-tooltip',attrs:{"content":"This tag is currently moving","placement":"top"}},[(
                _vm.resources.sidebarAnchor?.currentTagId === scope.row.tagId &&
                  _vm.hasBeenLessThanXSeconds(scope.row.lastTs, 10)
              )?_c('div',{staticClass:"status-circle"}):_vm._e()]):_vm._e(),(
              _vm.highlightedTag.beaconId === scope.row.beaconId &&
                _vm.highlightedTag.tagId === scope.row.tagId
            )?_c('el-tooltip',{key:scope.row.id,attrs:{"content":"Showing this tag on map","placement":"bottom"}},[(
                _vm.highlightedTag.beaconId === scope.row.beaconId &&
                  _vm.highlightedTag.tagId === scope.row.tagId &&
                  _vm.aimButton.tagId === scope.row.tagId &&
                  _vm.aimButton.isButtonActive
              )?_c('div',{staticClass:"status-circle-yellow"}):_vm._e()]):_vm._e()]}}])}),_c('el-table-column',{attrs:{"prop":"tagId","label":"Tag","sortable":"","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.tagId)?[(scope.row.tagLabel)?[_c('b',[_vm._v(_vm._s(scope.row.tagLabel))])]:_vm._e(),_c('br'),_c('span',{staticClass:"grey-subtitle"},[_vm._v("("+_vm._s(_vm.toHex(scope.row.tagId, "tag"))+")")])]:[_c('hr')]]}}])}),_c('el-table-column',{attrs:{"prop":"status","label":"Status","sortable":"","width":"160"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.status)?[_c('b',{staticStyle:{"text-transform":"capitalize"}},[_vm._v(_vm._s(scope.row.status.toLowerCase()))])]:[_c('hr')]]}}])}),_c('el-table-column',{attrs:{"label":"Unload Location","sortable":"","prop":"beaconLabel","min-width":"155"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.beaconLabel || scope.row.beaconId)?[(scope.row.beaconLabel)?[_c('b',[_vm._v(_vm._s(scope.row.beaconLabel))])]:_vm._e(),(scope.row.beaconId)?[_c('br'),_c('span',{staticClass:"grey-subtitle"},[_vm._v("("+_vm._s(_vm.toHex(scope.row.beaconId, "tag"))+")")])]:_vm._e()]:[_c('hr')]]}}])}),_c('el-table-column',{attrs:{"prop":"lastTs","label":"Unload Time","sortable":"","width":"130"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              _vm.resources.sidebarAnchor?.currentTagId === scope.row.tagId &&
                _vm.hasBeenLessThanXSeconds(scope.row.lastTs, 10)
            )?[_c('span',{staticClass:"last-update",domProps:{"innerHTML":_vm._s(_vm.getTimeStampTransformed(_vm.dashboardData))}})]:(scope.row.entryTs)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTimeStampTransformed(scope.row.lastTs))}})]:[_c('hr')]]}}])}),_c('el-table-column',{attrs:{"prop":"passedByBeacon","label":"Passed by Location","sortable":"","min-width":"155"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(
              (scope.row.passedByBeaconLabel || scope.row.passedByBeaconId) &&
                !scope.row.beaconLabel
            )?[(scope.row.passedByBeaconLabel)?[_c('b',[_vm._v(_vm._s(scope.row.passedByBeaconLabel))])]:_vm._e(),[_c('br'),_c('span',{staticClass:"grey-subtitle"},[_vm._v("("+_vm._s(_vm.toHex(scope.row.passedByBeaconId, "tag"))+")")])]]:[_c('hr')]]}}])}),_c('el-table-column',{attrs:{"prop":"passedByTs","label":"Passed by Time","sortable":"","width":"150"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [(scope.row.passedByTs && !scope.row.beaconLabel)?[_c('span',{domProps:{"innerHTML":_vm._s(_vm.getTimeStampTransformed(scope.row.passedByTs))}})]:[_c('hr')]]}}])}),_c('el-table-column',{attrs:{"width":"60","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-tooltip',{key:scope.row.tagId + '-tooltip',attrs:{"content":"Tag History ","placement":"left-start"}},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){$event.stopPropagation();return _vm.viewHistory(scope.row)}}},[_c('i',{staticClass:"el-icon-time"})])],1),_c('el-tooltip',{key:scope.row.id + '-tooltip',attrs:{"content":"See on Map","placement":"left-end"}},[_c('el-button',{attrs:{"size":"mini"},on:{"click":function($event){return _vm.highlightBeacon(scope.row.beaconId, scope.row.tagId)}}},[_c('i',{class:[
                  'el-icon-aim',
                  _vm.aimButton.tagId === scope.row.tagId &&
                  _vm.aimButton.isButtonActive
                    ? 'active'
                    : 'inactive'
                ]})])],1)]}}])})],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }