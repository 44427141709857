var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('nav',{staticClass:"header"},[_c('div',{staticClass:"container"},[_c('img',{staticClass:"header__logo",attrs:{"src":require("@/assets/logo-negative.svg"),"alt":"Azitek"},on:{"click":function($event){_vm.$route.name !== _vm.routerTypes.ROUTE_LIVE_GLOBAL_MAP &&
        !_vm.isChangePasswordUser &&
        _vm.user.data.clientType == 0
          ? _vm.changeView(_vm.routerTypes.ROUTE_LIVE_GLOBAL_MAP)
          : _vm.isTracker && _vm.$route.name !== _vm.routerTypes.ROUTE_DASHBOARD
          ? _vm.changeView(_vm.routerTypes.ROUTE_DASHBOARD)
          : !_vm.isClientDefault &&
            _vm.user.data.clientType == 1 &&
            _vm.$route.name !== _vm.routerTypes.ROUTE_CARGO_CHECK
          ? _vm.changeView(_vm.routerTypes.ROUTE_CARGO_CHECK)
          : !_vm.isClientDefault &&
            _vm.user.data.clientType == 4 &&
            _vm.$route.name !== _vm.routerTypes.ROUTE_ASSET_MANAGER
          ? _vm.changeView(_vm.routerTypes.ROUTE_ASSET_MANAGER)
          : !_vm.isClientDefault &&
            _vm.user.data.clientType == 3 &&
            _vm.$route.name !== _vm.routerTypes.ROUTE_INVENTORY_MANAGER
          ? _vm.changeView(_vm.routerTypes.ROUTE_INVENTORY_MANAGER)
          : ''}}}),_c('el-menu',{staticClass:"header__menu",attrs:{"mode":"horizontal","default-active":_vm.activeLink}},[(!_vm.isChangePasswordUser && _vm.user.data.clientType == 0)?_c('el-menu-item',{attrs:{"index":"live-global-map"},on:{"click":function($event){_vm.$route.name !== _vm.routerTypes.ROUTE_LIVE_GLOBAL_MAP
            ? _vm.changeView(_vm.routerTypes.ROUTE_LIVE_GLOBAL_MAP)
            : ''}}},[_vm._v("Overall Metrics")]):_vm._e(),(!_vm.isChangePasswordUser && _vm.isClientDefault)?_c('el-menu-item',{attrs:{"index":"dashboard"},on:{"click":function($event){_vm.$route.name !== _vm.routerTypes.ROUTE_DASHBOARD
            ? _vm.changeView(_vm.routerTypes.ROUTE_DASHBOARD)
            : ''}}},[(!_vm.isTracker)?_c('span',[_vm._v("Vehicle Metrics")]):_c('span',[_vm._v("Live Map")])]):_vm._e(),(!_vm.isClientDefault && _vm.user.data.clientType == 1)?_c('el-menu-item',{attrs:{"index":"cargo-check"},on:{"click":function($event){_vm.$route.name !== _vm.routerTypes.ROUTE_CARGO_CHECK
            ? _vm.changeView(_vm.routerTypes.ROUTE_CARGO_CHECK)
            : ''}}},[_vm._v("Cargo Check")]):_vm._e(),(!_vm.isClientDefault && _vm.user.data.clientType == 3)?_c('el-menu-item',{attrs:{"index":"inventory-manager"},on:{"click":function($event){_vm.$route.name !== _vm.routerTypes.ROUTE_INVENTORY_MANAGER
            ? _vm.changeView(_vm.routerTypes.ROUTE_INVENTORY_MANAGER)
            : ''}}},[_vm._v("Inventory Manager")]):_vm._e(),(!_vm.isClientDefault && _vm.user.data.clientType == 4)?_c('el-menu-item',{attrs:{"index":"asset-manager"},on:{"click":function($event){_vm.$route.name !== _vm.routerTypes.ROUTE_ASSET_MANAGER
            ? _vm.changeView(_vm.routerTypes.ROUTE_ASSET_MANAGER)
            : ''}}},[_vm._v("Asset Manager")]):_vm._e(),(!_vm.isChangePasswordUser && _vm.isClientDefault && !_vm.isTracker)?_c('el-submenu',{attrs:{"index":""}},[_c('template',{slot:"title"},[_vm._v("Analytics")]),_c('el-menu-item',{attrs:{"index":"stats-globals"},on:{"click":function($event){_vm.$route.name !== _vm.routerTypes.ROUTE_STATS_GLOBALS
              ? _vm.handleStatsDropdownCommand(_vm.routerTypes.ROUTE_STATS_GLOBALS)
              : ''}}},[_vm._v("Activity")]),_c('el-menu-item',{attrs:{"index":"stats-laps"},on:{"click":function($event){_vm.$route.name !== _vm.routerTypes.ROUTE_STATS_LAPS
              ? _vm.handleStatsDropdownCommand(_vm.routerTypes.ROUTE_STATS_LAPS)
              : ''}}},[_vm._v("Laps")])],2):_vm._e(),(!_vm.isChangePasswordUser && _vm.isClientDefault && !_vm.isTracker)?_c('el-menu-item',{attrs:{"index":"predictions-fullscreen-mode"},on:{"click":function($event){_vm.$route.name !== _vm.routerTypes.ROUTE_PREDICTIONS_FULLSCREEN
            ? _vm.changeView(_vm.routerTypes.ROUTE_PREDICTIONS_FULLSCREEN)
            : ''}}},[_vm._v("Predictions")]):_vm._e(),(!_vm.isChangePasswordUser)?_c('el-menu-item',{attrs:{"index":"user-settings"},on:{"click":function($event){_vm.$route.name !== _vm.routerTypes.ROUTE_USER_MAIN_TAB
            ? _vm.changeView(_vm.routerTypes.ROUTE_USER_SETTINGS)
            : ''}}},[_vm._v("Settings")]):_vm._e(),(_vm.isSuperUser)?_c('el-dropdown',{ref:"clientDropdown",staticClass:"header__menu__dropdowns left-margin",on:{"command":_vm.switchSuperUser}},[_c('span',{staticClass:"header__menu__dropdowns__super-user el-dropdown-link"},[_c('span',{staticClass:"super-user-dropdown-title"},[_vm._v(_vm._s(_vm.clientData.name)+" ")]),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{staticClass:"header__menu__dropdowns-dropdown",attrs:{"slot":"dropdown"},slot:"dropdown"},_vm._l((_vm.user.data.superUsers),function(client){return _c('el-dropdown-item',{key:client.id,class:[
              _vm.clientData.id === client.id
                ? 'anchor-selected'
                : 'anchor-not-selected'
            ],attrs:{"command":client.id}},[(_vm.clientData.id === client.id)?_c('i',{staticClass:"el-icon-circle-check"}):_vm._e(),_vm._v(" "+_vm._s(client.name)+" ")])}),1)],1):_vm._e(),_c('el-menu-item',{style:(_vm.isSuperUser
            ? 'font-weight: bold'
            : 'margin-left: 4vw; font-weight: bold'),attrs:{"index":"logout"},on:{"click":function($event){return _vm.logout()}}},[_vm._v(" Logout")])],1),_c('el-dropdown',{staticClass:"header__menu",attrs:{"trigger":"click"},on:{"command":_vm.handleCommand}},[_c('span',{staticClass:"el-dropdown-link"},[_vm._v(" Menu"),_c('i',{staticClass:"el-icon-arrow-down el-icon--right"})]),_c('el-dropdown-menu',{attrs:{"slot":"dropdown"},slot:"dropdown"},[(_vm.user.data.clientType == 0)?_c('el-dropdown-item',{attrs:{"command":_vm.routerTypes.ROUTE_LIVE_GLOBAL_MAP}},[_vm._v("Overall Metrics")]):_vm._e(),(_vm.isClientDefault)?_c('el-dropdown-item',{attrs:{"command":_vm.routerTypes.ROUTE_DASHBOARD}},[(!_vm.isTracker)?_c('span',[_vm._v("Vehicle Metrics")]):_c('span',[_vm._v("Live Map")])]):_vm._e(),(!_vm.isClientDefault && _vm.user.data.clientType == 1)?_c('el-dropdown-item',{attrs:{"command":_vm.routerTypes.ROUTE_CARGO_CHECK}},[_vm._v("Cargo Check")]):_vm._e(),(!_vm.isClientDefault && _vm.user.data.clientType == 3)?_c('el-dropdown-item',{attrs:{"command":_vm.routerTypes.ROUTE_INVENTORY_MANAGER}},[_vm._v("Inventory Manager")]):_vm._e(),(!_vm.isClientDefault && _vm.user.data.clientType == 4)?_c('el-dropdown-item',{attrs:{"command":_vm.routerTypes.ROUTE_ASSET_MANAGER}},[_vm._v("Asset Manager")]):_vm._e(),(_vm.user.data.clientType == 0)?_c('el-dropdown-item',{attrs:{"command":_vm.routerTypes.ROUTE_STATS_GLOBALS}},[_vm._v("Analytics > Activity")]):_vm._e(),(_vm.user.data.clientType == 0)?_c('el-dropdown-item',{attrs:{"command":_vm.routerTypes.ROUTE_STATS_LAPS}},[_vm._v("Analytics > Laps")]):_vm._e(),(_vm.user.data.clientType == 0)?_c('el-dropdown-item',{attrs:{"command":_vm.routerTypes.ROUTE_PREDICTIONS_FULLSCREEN}},[_vm._v("Predictions")]):_vm._e(),_c('el-dropdown-item',{attrs:{"command":_vm.routerTypes.ROUTE_USER_SETTINGS}},[_vm._v("Settings")]),_c('el-dropdown-item',{attrs:{"command":"logout"}},[_vm._v("Logout")])],1)],1)],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }