<template>
  <span :class="wifiSignalClasses(signal)">
    <div v-if="noConnection" class="wifi__no-connection" />
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="32pt"
      height="45pt"
      viewBox="0 0 32 45"
      version="1.1"
    >
      <g id="surface1">
        <path
          style=" stroke:none;fill-rule:nonzero;fill:#1F4258;fill-opacity:0.15;"
          d="M 15.585938 12.035156 C 10.8125 12.054688 6.050781 13.832031 2.398438 17.363281 L 4.785156 19.707031 C 10.761719 13.953125 20.367188 13.921875 26.378906 19.648438 L 28.8125 17.261719 C 25.136719 13.757812 20.359375 12.019531 15.585938 12.035156 Z M 4.785156 19.707031 L 4.761719 19.730469 Z M 4.785156 19.707031 "
        />
        <path
          style=" stroke:none;fill-rule:nonzero;fill:#1F4258;fill-opacity:0.15;"
          d="M 15.589844 17.082031 C 12.128906 17.097656 8.675781 18.386719 6.027344 20.945312 L 8.433594 23.308594 C 12.402344 19.476562 18.789062 19.453125 22.785156 23.261719 L 25.183594 20.875 C 22.515625 18.332031 19.050781 17.070312 15.589844 17.082031 Z M 8.433594 23.308594 L 8.425781 23.3125 Z M 8.433594 23.308594 "
        />
        <path
          style=" stroke:none;fill-rule:nonzero;fill:#1F4258;fill-opacity:0.15;"
          d="M 15.585938 22.109375 C 13.429688 22.117188 11.277344 22.921875 9.628906 24.515625 L 11.929688 26.773438 C 13.957031 24.8125 17.222656 24.804688 19.265625 26.75 L 21.554688 24.46875 C 19.894531 22.886719 17.738281 22.101562 15.585938 22.109375 Z M 11.929688 26.773438 L 11.925781 26.777344 Z M 11.929688 26.773438 "
        />
        <path
          style=" stroke:none;fill-rule:nonzero;fill:#1F4258;fill-opacity:0.15;"
          d="M 13.222656 32.769531 C 11.90625 31.460938 11.90625 29.335938 13.222656 28.023438 C 14.539062 26.710938 16.671875 26.707031 17.992188 28.015625 C 19.3125 29.324219 19.320312 31.449219 18.003906 32.761719 C 16.691406 34.078125 14.554688 34.085938 13.234375 32.777344 L 15.609375 30.394531 Z M 13.222656 32.769531 "
        />
      </g>
    </svg>
  </span>
</template>

<script>
export default {
  name: "WifiIcon",

  props: {
    signal: {
      type: Number,
      required: true
    },
    noConnection: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    wifiSignalClasses(value) {
      const classes = ["wifi"];

      if (this.noConnection) {
        return classes;
      }

      if (value < -80) {
        classes.push("wifi--poor");
      } else if (value >= -80 && value < -70) {
        classes.push("wifi--low");
      } else if (value >= -70 && value < -50) {
        classes.push("wifi--medium");
      } else if (value >= -50) {
        classes.push("wifi--high");
      }
      return classes;
    }
  }
};
</script>

<style lang="scss">
.wifi {
  position: relative;

  &__no-connection {
    position: absolute;
    top: 25px;
    left: 7px;
    width: 43px;
    height: 5px;
    background: #1f4258;
    transform: rotate(305deg);
  }

  &--poor svg {
    path:nth-child(4) {
      fill-opacity: 1 !important;
    }
  }

  &--low svg {
    path:nth-child(3),
    path:nth-child(4) {
      fill-opacity: 1 !important;
    }
  }

  &--medium svg {
    path:nth-child(2),
    path:nth-child(3),
    path:nth-child(4) {
      fill-opacity: 1 !important;
    }
  }

  &--high svg {
    path:nth-child(1),
    path:nth-child(2),
    path:nth-child(3),
    path:nth-child(4) {
      fill-opacity: 1 !important;
    }
  }
}
</style>
