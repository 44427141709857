<template>
  <div class="tag-list">
    <div class="header-content">
      <h3>Tags Data</h3>
      <div class="header-content__details">
        <span>Quantity: {{ assetList?.length }}</span>
        <div class="header-content-display-flex__inputs">
          <!-- <span class="search-span">
            <input
              id="search-asset-input"
              type="text"
              v-model="search"
              placeholder="Search by Asset or Location"
              v-on:input="searchBools()"
            />
          </span>
          <span class="close-search" v-if="search.length">
            <i class="el-icon-error" @click.stop="search = ''"> </i>
          </span> -->
          <div class="autocomplete-search">
            <el-autocomplete
              class="autocomplete-search__input-bar"
              v-model="search"
              :fetch-suggestions="fetchSuggestions"
              placeholder="Search by Tag or Location"
              @select="handleSelectAsset"
              @clear="clearSearchInfo"
              @input="clearSearchInfo"
              clearable
            >
              <template v-slot:empty>
                <div class="no-match-message">No match found</div>
              </template>
            </el-autocomplete>
          </div>
        </div>
      </div>
    </div>
    <div clas="table-container">
      <el-table
        :data="searchedInfo.length != 0 ? searchedInfo : assetList"
        class="asset-list-table"
        style="width: 100%"
        :row-class-name="tableRowClassName"
        :max-height="windowInnerHeight - 250"
      >
        <el-table-column width="25px" align="start">
          <template slot-scope="scope">
            <el-tooltip
              v-if="
                resources.sidebarAnchor?.currentTagId === scope.row.tagId &&
                  hasBeenLessThanXSeconds(scope.row.lastTs, 10)
              "
              content="This tag is currently moving"
              placement="top"
              :key="scope.row.beaconId + '-tooltip'"
            >
              <div
                class="status-circle"
                v-if="
                  resources.sidebarAnchor?.currentTagId === scope.row.tagId &&
                    hasBeenLessThanXSeconds(scope.row.lastTs, 10)
                "
              ></div>
            </el-tooltip>
            <el-tooltip
              v-if="
                highlightedTag.beaconId === scope.row.beaconId &&
                  highlightedTag.tagId === scope.row.tagId
              "
              content="Showing this tag on map"
              placement="bottom"
              :key="scope.row.id"
            >
              <div
                class="status-circle-yellow"
                v-if="
                  highlightedTag.beaconId === scope.row.beaconId &&
                    highlightedTag.tagId === scope.row.tagId &&
                    aimButton.tagId === scope.row.tagId &&
                    aimButton.isButtonActive
                "
              ></div>
            </el-tooltip>
          </template>
        </el-table-column>
        <el-table-column prop="tagId" label="Tag" sortable width="150">
          <template slot-scope="scope">
            <template v-if="scope.row.tagId">
              <template v-if="scope.row.tagLabel">
                <b>{{ scope.row.tagLabel }}</b>
              </template>
              <br /><span class="grey-subtitle"
                >({{ toHex(scope.row.tagId, "tag") }})</span
              >
            </template>
            <template v-else>
              <hr />
            </template>
          </template>
        </el-table-column>
        <el-table-column prop="status" label="Status" sortable width="160">
          <template slot-scope="scope">
            <template v-if="scope.row.status">
              <b style="text-transform: capitalize;">{{
                scope.row.status.toLowerCase()
              }}</b>
            </template>
            <template v-else>
              <hr />
            </template>
          </template>
        </el-table-column>

        <el-table-column
          label="Unload Location"
          sortable
          prop="beaconLabel"
          min-width="155"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.beaconLabel || scope.row.beaconId">
              <template v-if="scope.row.beaconLabel">
                <b>{{ scope.row.beaconLabel }}</b>
              </template>
              <template v-if="scope.row.beaconId">
                <br /><span class="grey-subtitle"
                  >({{ toHex(scope.row.beaconId, "tag") }})</span
                >
              </template>
            </template>
            <template v-else>
              <hr />
            </template>
          </template>
        </el-table-column>

        <el-table-column prop="lastTs" label="Unload Time" sortable width="130">
          <template slot-scope="scope">
            <template
              v-if="
                resources.sidebarAnchor?.currentTagId === scope.row.tagId &&
                  hasBeenLessThanXSeconds(scope.row.lastTs, 10)
              "
            >
              <span
                class="last-update"
                v-html="getTimeStampTransformed(dashboardData)"
              >
              </span>
            </template>
            <template v-else-if="scope.row.entryTs">
              <span v-html="getTimeStampTransformed(scope.row.lastTs)"></span>
            </template>
            <template v-else>
              <hr />
            </template>
          </template>
        </el-table-column>

        <el-table-column
          prop="passedByBeacon"
          label="Passed by Location"
          sortable
          min-width="155"
        >
          <template slot-scope="scope">
            <template
              v-if="
                (scope.row.passedByBeaconLabel || scope.row.passedByBeaconId) &&
                  !scope.row.beaconLabel
              "
            >
              <template v-if="scope.row.passedByBeaconLabel">
                <b>{{ scope.row.passedByBeaconLabel }}</b>
              </template>
              <template>
                <br /><span class="grey-subtitle"
                  >({{ toHex(scope.row.passedByBeaconId, "tag") }})</span
                >
              </template>
            </template>
            <template v-else>
              <hr />
            </template>
          </template>
        </el-table-column>
        <el-table-column
          prop="passedByTs"
          label="Passed by Time"
          sortable
          width="150"
        >
          <template slot-scope="scope">
            <template v-if="scope.row.passedByTs && !scope.row.beaconLabel">
              <span
                v-html="getTimeStampTransformed(scope.row.passedByTs)"
              ></span>
            </template>
            <template v-else>
              <hr />
            </template>
          </template>
        </el-table-column>

        <el-table-column width="60" align="center">
          <template slot-scope="scope">
            <el-tooltip
              content="Tag History "
              placement="left-start"
              :key="scope.row.tagId + '-tooltip'"
            >
              <el-button size="mini" @click.stop="viewHistory(scope.row)">
                <i class="el-icon-time"></i>
              </el-button>
            </el-tooltip>
            <el-tooltip
              content="See on Map"
              placement="left-end"
              :key="scope.row.id + '-tooltip'"
            >
              <el-button
                size="mini"
                @click="highlightBeacon(scope.row.beaconId, scope.row.tagId)"
              >
                <i
                  :class="[
                    'el-icon-aim',
                    aimButton.tagId === scope.row.tagId &&
                    aimButton.isButtonActive
                      ? 'active'
                      : 'inactive'
                  ]"
                ></i>
              </el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapState } from "vuex";
import tagsApi from "@/modules/tags/api/tags.api";
import { normalizeString } from "@/utils/utils";

export default {
  name: "assetList",
  props: {
    assetList: {
      type: Array,
      default: () => []
    },
    beaconList: {
      type: Array,
      default: () => []
    },
    clusterClickActive: {
      type: Boolean,
      default: false
    },
    gatewayLabel: {
      type: String,
      default: ""
    },
    currentTagStartTs: {
      type: Number,
      default: 1
    }
  },

  data() {
    return {
      search: "",
      searchedInfo: [],
      // searched: false,
      // searchFinal: false,
      searchPage: 0,
      searchPageSize: 20,
      currentTime: "",
      // lastSeenLocations: {},
      loadingLastSeen: true,
      highlightedTag: {
        beaconId: null,
        tagId: null
      },
      aimButton: {
        tagId: null,
        isButtonActive: false
      },
      windowInnerHeight: window.innerHeight
    };
  },

  // watch: {
  //   searchFinal: {
  //     handler(newValue) {
  //       if (newValue) {
  //         this.searchedInfo = [];
  //         if (this.search.length >= 3) {
  //           const search = this.search
  //             .normalize("NFD")
  //             .replace(/[\u0300-\u036f]/g, "")
  //             .toLowerCase();
  //           this.assetList.forEach(asset => {
  //             if (
  //               this.toHex(asset.tagId)
  //                 .toLowerCase()
  //                 .includes(search) ||
  //               asset.tagLabel.toLowerCase().includes(search) ||
  //               this.toHex(asset.beaconId)
  //                 .toLowerCase()
  //                 .includes(search) ||
  //               asset.beaconLabel.toLowerCase().includes(search)
  //             ) {
  //               this.searchedInfo.push(asset);
  //             }
  //           }, this);
  //           //for (let i = 0; i < res.data.length; i++) {
  //           // this.searchedInfo.push(res.data[i]);
  //           //}
  //         }
  //         this.searchFinal = false;
  //         this.searched = true;
  //       }
  //     }
  //   },

  //   search: {
  //     handler(newValue) {
  //       if (newValue === "") {
  //         this.searchedInfo = [];
  //       }
  //       this.searched = false;
  //     }
  //   }
  // },

  computed: {
    ...mapState("clients", {
      clientData: "data"
    }),
    ...mapState("dashboard", {
      dashboardData: "data"
    }),
    ...mapState("user", {
      resources: "resources"
    })
  },

  methods: {
    toHex(str, type) {
      if (!str) return "";
      if (type === "timestamp") return this.getTimeStampTransformed(str);
      else {
        return str
          .toString(16)
          .toUpperCase()
          .match(/.{1,2}/g)
          .join(type === "anchor" ? ":" : "");
      }
    },

    hasBeenLessThanXSeconds(timestamp, interval) {
      const now = moment.tz(this.clientData.timezone).unix(); // Current time in seconds

      const clientTimestampInSeconds = moment
        .unix(timestamp)
        .tz(this.clientData.timezone)
        .unix();
      const diffInSeconds = now - clientTimestampInSeconds;
      return diffInSeconds < interval;
    },

    tableRowClassName(tableData) {
      if (
        this.resources.sidebarAnchor &&
        tableData.row.tagId === this.resources.sidebarAnchor.currentTagId &&
        !this.gatewayOffline
      ) {
        return "currently-here";
      }

      return "";
    },

    // searchBools() {
    //   this.searchFinal = true;
    //   this.searched = false;
    // },

    getTimeStampTransformed(timestamp) {
      if (timestamp) {
        const formattedDate = moment
          .tz(moment.unix(timestamp), this.clientData.timezone)
          .format("MMM DD YYYY, HH:mm:ss");

        return formattedDate.replace(",", ",<br>");
      }
      return "N/A";
    },

    // Checks if any asset matches the searched item, if a match is found, adds the match to the results array
    doesAssetMatchSearch(asset, search, results = null) {
      const matchField = [
        asset.tagId ? this.toHex(asset.tagId) : "",
        asset.beaconId ? this.toHex(asset.beaconId) : "",
        asset.tagLabel ? asset.tagLabel.toUpperCase() : "",
        asset.beaconLabel ? asset.beaconLabel.toUpperCase() : ""
      ].find(field => field.includes(search));

      if (matchField) {
        if (results !== null) {
          results.push({
            value: matchField,
            data: asset
          });
        }
        return true;
      }
      return false;
    },

    // Fetches suggestions for the autocomplete based on the query string
    fetchSuggestions(queryString, cb) {
      const search = normalizeString(queryString);
      const results = [];

      const match = this.assetList.filter(asset =>
        this.doesAssetMatchSearch(asset, search, results)
      );

      if (!match) {
        cb([{ value: "No match found", disabled: true }]);
      } else {
        this.searchedInfo = match;
        cb(results);
      }
    },

    // Handles the selection of an asset from the autocomplete suggestions
    handleSelectAsset(searchedAsset) {
      if (searchedAsset.value === "No match found") {
        return;
      } else if (searchedAsset === "") {
        this.searchedInfo = [];
        return;
      }
      this.searchedInfo = [];
      const search = normalizeString(searchedAsset.value);

      this.searchedInfo = this.assetList.filter(asset =>
        this.doesAssetMatchSearch(asset, search)
      );
    },

    // Clears the searchedInfo array
    clearSearchInfo() {
      this.searchedInfo = [];
    },

    // Returns tag history sorted by last seen timestamp and replaces beacon IDs with hex values and labels
    // async getTagHistory(tag, pageSize) {
    //   const res = await tagsApi.getTagHistory(tag, pageSize);
    //   const tagHistory = res.data;

    //   tagHistory.sort((a, b) => b.lastSeenTs - a.lastSeenTs);

    //   const foundTag = this.resources.data.tags.find(
    //     tag => tag.id == tagHistory[0].beaconId
    //   )

    //   if (foundTag) {
    //     return {
    //       beaconId: this.toHex(foundTag.tagId),
    //       beaconLabel: foundTag.label
    //     }
    //   }
    //   return {};
    // },

    // // Returns the last seen location for a given tag ID from its tag history
    // async getLastSeenLocation(tagId) {
    //   const tagHistory = await this.getTagHistory(tagId, 1000);
    //   if (!tagHistory) {
    //     throw new Error("No tag history found");
    //   }

    //   const lastSeenLocation = tagHistory.find(history => history.beaconLabel);
    //   console.log(lastSeenLocation)
    //   return lastSeenLocation
    //     ? {
    //         beaconId: lastSeenLocation.beaconId,
    //         beaconLabel: lastSeenLocation.beaconLabel
    //       }
    //     : null;
    // },

    // // Fetches last seen locations for each asset in the asset list and updates lastSeenLocations
    // async fetchLastSeenLocations() {
    //   try {
    //     for (const asset of this.assetList) {
    //       const lastSeenLocation = await this.getLastSeenLocation(asset.tagId);
    //       this.$set(
    //         this.lastSeenLocations,
    //         asset.tagId,
    //         lastSeenLocation || "N/A"
    //       );
    //     }
    //   } catch (err) {
    //     console.log(err);
    //   } finally {
    //     this.loadingLastSeen = false;
    //   }
    // },

    changeAimButtonStatus(tagId) {
      if (!this.aimButton.tagId) {
        this.aimButton.tagId = tagId;
        this.aimButton.isButtonActive = true;
      } else if (
        this.highlightedTag.tagId === tagId &&
        this.aimButton.tagId === tagId
      ) {
        this.aimButton.isButtonActive = !this.aimButton.isButtonActive;
      } else {
        this.aimButton.tagId = tagId;
        this.aimButton.isButtonActive = true;
      }
    },

    viewHistory(obj) {
      this.$emit("view-history", obj);
    },

    updateHighlightedBeacon(beacon, prevTagId) {
      this.$emit("highlight-beacon", beacon, prevTagId);
    },

    highlightBeacon(beaconId, tagId) {
      this.beaconList.forEach(beacon => {
        if (beacon.tagId == beaconId) {
          if (
            this.highlightedTag.beaconId === beaconId &&
            this.highlightedTag.tagId !== tagId &&
            this.aimButton.isButtonActive
          ) {
            this.updateHighlightedBeacon();
            delete beacon.colorToHighlight;
          } else {
            beacon.colorToHighlight = "#ffd700";
            this.updateHighlightedBeacon(beacon, this.highlightedTag.beaconId);
          }
          this.highlightedTag.beaconId = beaconId;
          this.highlightedTag.tagId = tagId;
          this.changeAimButtonStatus(tagId);
          return;
        }
      });
    }
  },

  mounted() {
    this.$nextTick(() => {
      window.onresize = () => {
        this.windowInnerHeight = window.innerHeight;
      };
    });
  }
};
</script>

<style lang="scss">
// .pagination-wrapper {
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;

//   &__current {
//     height: 32px;
//     display: flex;
//     align-items: center;
//   }
// }
// #search-asset-input {
//   height: 30px;
//   width: 200px;

//   margin-bottom: 0.5rem;
// }

// .close-search {
//   display: inline-block;
//   position: relative;
//   margin-left: 1rem;
//   font-size: 30px;
//   top: 4px;

//   &:hover {
//     cursor: pointer;
//   }
// }

.tag-list {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px 8px;
  width: 97%;

  @media (max-width: $lg) {
    width: 96%;
  }

  .title {
    padding-bottom: 15px;
  }

  input {
    padding: 4px 12px;
    color: rgba(0, 0, 0, 0.7);
    border: 1px solid rgba(0, 0, 0, 0.12);
    transition: 0.15s all ease-in-out;
    background: white;
    &:focus {
      outline: none;
      transform: scale(1.05);
      & + label {
        font-size: 10px;
        transform: translateY(-24px) translateX(-12px);
      }
    }
    &::-webkit-input-placeholder {
      font-size: 12px;
      color: rgba(0, 0, 0, 0.5);
      font-weight: 100;
    }
  }

  .header-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 5px;

    @media (max-width: 500px) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 10px 0 10px;
    }

    &__details {
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 40px;

      @media (max-width: 500px) {
        justify-content: space-between;
        width: 100%;
      }
    }

    .header-content-display-flex {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      position: relative;
      width: 100%;

      &__inputs {
        display: flex;
      }

      &__search {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
      }
    }
  }

  .cell {
    .el-button--mini {
      padding: 2px;
      border: none;
      background-color: inherit;
      color: #8993a0;
      cursor: pointer;

      .el-icon-time,
      .el-icon-aim {
        font-size: 14px;
        font-weight: bold;
      }
      .active {
        color: #ffd700;
      }
      .inactive {
        color: #8993a0;
      }
    }
    .el-button {
      margin-left: 0;
    }
    .el-button--mini:hover {
      color: $--color-primary;
    }
  }

  .asset-list-table {
    margin-top: 5px;
  }

  .table-container {
    width: 100%;
    overflow: hidden;
  }

  .el-table {
    border-radius: 6px;
    color: $--color-primary;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    overflow-y: auto;

    .status-circle {
      background-color: rgba(50, 205, 50, 0.7);
      width: 10px;
      height: 10px;
      border-radius: 100%;
    }
    .status-circle-yellow {
      background-color: #ffd700;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      margin-top: 5px;
    }

    .last-update {
      font-weight: bold;
      color: rgba(29, 173, 29, 0.7);
    }

    .el-table__header-wrapper th {
      background-color: #f8f9fa;
      color: $--color-primary;
      font-weight: bold;
      font-size: 14px;
    }

    .el-table__body {
      td,
      th {
        padding: 9px 2px !important;
      }
    }

    .grey-subtitle {
      color: #656f7d;
      font-weight: bold;
      font-size: 12px;
    }
  }

  // .el-table__row.currently-here {
  //   background: rgba(50, 205, 50, 0.7);

  //   .cell {
  //     color: #2c3e50;
  //   }
  // }
}
</style>
